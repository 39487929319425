@import "helpers/strip-unit";

/**
 * Main container mixin
 *
 */
@mixin container($max-width: $max-width, $gutter-rem: $gutter, $base-fontsize: $fs_base) {
  width: $max-width * 1px;
  margin-left: auto;
  margin-right: auto;
  padding-left: $gutter-rem;
  padding-right: $gutter-rem;

  @media all and (min-width: #{$max-width + $base-fontsize * strip-unit($gutter-rem) }) {
    padding-left: 0;
    padding-right: 0;
  }
}
