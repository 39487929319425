// TODO: Continue with icons
@mixin NavList {
  &_list {
    margin: 0;
  }
  &_item {
  }
  &_link {
    // display: block;
    position: relative;
    @include button-list();
  }
  &_text {
    flex-grow: 1; // Push possible icons to the edges
  }
  &_enum {
    display: block;
    font-size: $fs_xsmall;
  }
  &_title {
    display: block;
    white-space: normal;
  }

  svg {
    fill: transparent;
  }
}

.NavList {
  @include NavList;
}
