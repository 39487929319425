$max-width: 1200;
$gutter: 1.333rem;
$gutter_xlarge: 3.8rem;

$spacing-xxxxxs: 3px;
$spacing-xxxxs: 6px;
$spacing-xxxs: 9px;
$spacing-xxs: 12px;
$spacing-xs: 18px;
$spacing-s: 24px;
$spacing-m: 30px;
$spacing-l: 60px;

$fs_2xsmall: 10px;
$fs_xsmall: 12px;
$fs_small: 14px;
$fs_medium_small: 16px;
$fs_base: 18px;
$fs_medium: 20px;
$fs_medium_large: 24px;
$fs_large: 28px;
$fs_xlarge: 32px;
$fs_2xlarge: 42px;
$fs_3xlarge: 72px;

$fw_extrabold: 900;
$fw_bold: bold;
$fw_semibold: 600;
$fw_medium: 500;
$fw_medium_normal: 450;
$fw_normal: 400;

$lh_base: 1.5;
$lh_header: 1.2;

$ff_body: "Circular", sans-serif;

$border-radius: 0px;

$time-quick: 350ms;
$time-medium: 500ms;
$time-slow: 800ms;

$tablet_width: 768px;
$desktop_width: 1024px;
$large_desktop_width: 1280px;
$xlarge_desktop_width: 1440px;

$header-height: 60px;
$w_menu-width: 366px;
$w_page-content-width: 940px;

$overlay-z-index: 101;

//____WORKBOOK_____
$m_default: 1em;
$m_large: 2em;
$m_xsmall: 0.5em;
