.guide-viewer-requirements {
  max-width: $w_page-content-width;
  width: 100%;
  margin: 4rem auto;

  &_title {
    @extend .h1;
  }

  &_main {
    &_title {
      font-size: 1.875em;
      font-weight: $fw_medium_normal;
      line-height: 1.2em;
      height: 2.4em;
      margin: 1.33em 0 0 0;
    }
  }
}
