.Selector {
  position: relative;
  &_button {
    @include button-select("Selector");
    width: 100%;
  }
  &_list {
    position: absolute;
    z-index: 2;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  &_option {
    @include button-list;
    display: block; // For the text-overflow to work.
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &_icon {
    min-width: 16px;
  }
  &[data-state-open="true"] &_icon {
    transform: rotate(180deg);
  }
}
