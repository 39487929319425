@import "src/styles/shared";

.Carousel {
  position: relative;
  max-width: 100%;
  overflow: hidden;

  &__arrow {
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 50px;
    width: 50px;
    // border-radius: $ra_round;
    // @include theme-background-color(c_body-bg-transp);
    svg {
      height: 15px;
      width: auto;
      path {
        // @include theme-fill(c_more-faded);
      }
    }

    &--left {
      left: $spacing-m;

      svg {
        margin-left: -3px;
      }
    }
    &--right {
      right: $spacing-m;

      svg {
        margin-right: -3px;
        transform: rotate(180deg);
      }
    }
  }
  &:not(.Carousel--shouldRenderCarousel) {
    display: flex;
    .CarouselItem {
      flex: 1 0 0;
    }
  }

  &--shouldRenderCarousel {
    &:after {
      content: " ";
      display: block;
      position: absolute;
      width: 71px;
      top: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      transition: opacity $time-quick ease;
      //   @include theme-diverge($theme-name_byggforetagen) {
      //     background-image: $gr_byggforetagen_carouselfade;
      //   }
      //   @include theme-diverge($theme-name_buc) {
      //     background-image: $gr_buc_carouselfade;
      //   }
      opacity: 1;
    }
    margin-right: -#{$spacing-s};
    @media all and (min-width: $tablet_width) {
      margin-right: -#{$spacing-m};
    }
    @media all and (min-width: $desktop_width) {
      margin-right: -#{$spacing-l};
    }
    &.Carousel--isOnLastSlide {
      &:after {
        opacity: 0;
      }
    }
  }

  &__swipeicon {
    pointer-events: none;
    position: absolute;
    right: 71px;
    top: 50%;
    transform: translateY(-50%);
    animation: carouselswipeiconmove 2s infinite;
    opacity: 1;
    transition: opacity $time-quick ease;
  }
  &--hasInteracted {
    .Carousel__swipeicon {
      opacity: 0;
    }
  }
}

@keyframes carouselswipeiconmove {
  0% {
    right: 71px;
  }
  12% {
    right: 91px;
  }
  25% {
    right: 71px;
  }
  100% {
    right: 71px;
  }
}
