.tools-menu {
  display: flex;
  align-items: center;
  color: $c_user_component_icon;
  position: relative;

  &_notification {
    .icon-modal_modal {
      background-color: $c_concrete-shade-2;
    }
    .icon-modal_button {
      padding: 1em 0.5em 1em 1em;
      background-color: transparent;
      border-color: transparent;
      flex-direction: row-reverse;

      .icon {
        transform: translateY(2px);
        margin-left: 0.5em;
      }
    }
  }

  &_menu_item {
    text-align: center;
  }

  &_link {
    color: $c_grey-black;
    background: none;
    padding: 1em;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
}
