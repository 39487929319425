@import "src/styles/variables";
@import "src/styles/colors";
@import "src/styles/typography";

.Chapter {
  width: 100%;
  padding: $gutter_xlarge;
  background-color: $c_bg_inverted;
  color: $c_text-inverted;

  a {
    color: $c_anchor-inverted;
    &:hover {
      text-decoration: underline;
    }
  }

  //   @include theme-background-color(c_section-bg);

  //   min-height: calc(100vh - #{$h_breadcrumb});
  //   @media all and (min-width: $beta_query) {
  //     min-height: calc(100vh - #{$h_breadcrumb-beta});
  //   }
  //   @media all and (min-width: $charlie_query) {
  max-width: calc(100% - #{$w_menu-width});
  //     flex: 1 1 auto;
  //     border-left: 6px solid transparent;
  //     @include theme-border-color(c_inverse-bg);
  //   }

  &__intro {
    margin-bottom: 3.8rem;
    //     text-align: center;

    //     // Only buc theme has an actual bg on
    //     // the chapter intro
    //     @include theme-diverge($theme-name_buc) {
    //       background-image: $gr_buc_chapter_intro;
    //     }
  }
  &__title {
    @extend .h1;
    // font-weight: $fw_extrabold;
    // line-height: 3.38rem;
    // letter-spacing: -0.2px;
    //     @include heading(1);
    //     padding: $m_medium_large;
    //     @media all and (min-width: $beta_query) {
    //       padding: $m_large $m_xxlarge;
    //       @include theme-font-size(s_xxlarge);
    //       line-height: $lh_small;
    //       letter-spacing: -0.4px;
    //     }
  }
  &__description {
    font-size: $fs_medium;
    line-height: 30px;
    // margin: 0 auto;
    // @include theme-color(c_faded);
    // @include theme-font-size(s_medium);
    // line-height: $lh_medium;
    // @include gutters;
    // max-width: 500px;
    // padding-bottom: $m_medium_large;
    // @media all and (min-width: $beta_query) {
    //   margin: 0 auto;
    //   max-width: $w_main-maxwidth;
    //   @include theme-font-size(s_large);
    //   line-height: $lh_small;
    //   // padding: 0 0 $m_xxlarge;
    //   padding-bottom: $m_xxlarge;
    // }
  }
  //   &__icon {
  //     margin: 0 auto $m_medium_large;
  //     text-align: center;
  //     height: 60px;
  //     @media all and (min-width: $beta_query) {
  //       height: $m_xxxlarge;
  //     }
  //     img {
  //       @include image;
  //       height: 100%;
  //       width: auto;
  //     }
  //   }
  //   &__audio {
  //     margin: $m_default 0;
  //     @include gutters;
  //   }
  &__chapternumber {
    //     @include heading(4);
    display: inline-block;
    border-bottom: 6px solid;
    margin-bottom: 0.75rem;
    //     @include theme-border-color(c_body-bg);
    //     @include theme-color(c_faded);
    //     padding-top: $m_xxlarge;
    //     padding-bottom: $m_medium_large;
    //     @media all and (min-width: $beta_query) {
    //       @include heading(3);
    //       border-bottom: 6px solid transparent;
    //       @include theme-border-color(c_body-bg);
    //       padding-bottom: $m_large;
    //       padding-top: $m_xxxlarge;
    //     }
  }
  &__subheading {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 21px;
    color: #7f8a8f;
    //@extend .h6;

    //     @include heading(6);
    //     @include theme-background-color(c_body-bg);
    //     padding: $m_small 0;
    //     @include gutters;
    //     @media all and (min-width: $beta_query) {
    //       padding-top: $m_default;
    //       padding-bottom: $m_default;
    //     }
    //     @media all and (min-width: $charlie_query) {
    //       @include heading(3);
    //     }
    //   }
    //   &__intersection {
    //     padding: $m_large 0 0;
    //     @include gutters;
    //     @media all and (min-width: $charlie_query) {
    //       padding-bottom: $m_large;
    //     }
    //     @media all and (min-width: $delta_query) {
    //       padding-bottom: $m_xlarge;
    //     }
    //   }
    //   &--isChapterCompleted {
    //     .Chapter__title {
    //       @include theme-color(c_progress !important);
    //     }
    //     .Chapter__subheading {
    //       &--tasks {
    //         @include theme-color(c_progress !important);
    //       }
    //     }
    //   }
    //   &--isSectionSuccess {
    //     .Chapter__title {
    //       @include theme-color(c_success !important);
    //     }
    //     .Chapter__subheading {
    //       &--tasks {
    //         @include theme-color(c_success !important);
    //       }
    //     }
  }
}
