@use "src/styles/variables";

.layout-icons {
  display: flex;
  gap: var(--layout-icons--gap);
  justify-content: var(--layout-icons--justify, start);

  &[data-variant="compressed"] {
    --layout-icons--gap: #{variables.$spacing-xxxs};
  }

  &[data-variant="regular"] {
    --layout-icons--gap: #{variables.$spacing-xxs};
  }

  &[data-variant="spaced"] {
    --layout-icons--gap: #{variables.$spacing-xs};
  }
}
