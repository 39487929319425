.guide-viewer-response-slot {
  display: grid;
  grid-template-columns: .62fr .38fr;
  margin-bottom: 1em;
  color: $c_cement-3;
  
  &_libdocs {
    min-width: 22.87em;
    margin-right: 6.13em;
    .button-link {
      display: flex;
      align-items: flex-start;
      text-align: left;
    }
  }

  .upload-files {
    max-width: 17.81em;
    &_row {
      // override the default UploadFiles styling
      border: 0;
      padding: 0;
      // add specific UploadFiles styling
      max-width: 23.75em;
      margin-bottom: 1em;

      & > .button-link {
        min-width: 17.81em;
        border: 2px solid white;
        padding: 0.75em 1.5em;
        justify-content: center;
      }
    }

    &_topbar {
      .button-link {
        display: flex;
        align-items: flex-start;
        text-align: left;
        color: white;
        margin-right: 1em;
      }
    }
  }
}
