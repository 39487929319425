.workbook {
  // background-color: $c_bg_dark;
  padding-top: 60px;

  &_back {
    @include button-factory-squareicon("S");
    &_sublabel {
      font-size: $fs_xsmall;
    }
    transform: translateY(-30px);
  }

  &_inner {
    @media all and (min-width: $desktop_width) {
      display: flex;
    }
    .Course-section-chapter-nav {
      width: $w_menu-width;
    }
    .Course-section-chapter-nav_list {
      margin-top: 0;
    }

    .Course-section-chapter-nav_link {
      &[data-active="true"] {
        background-color: $c_offwhite;
        border-color: $c_offwhite;
        svg {
          fill: transparent;
        }
      }
      &:hover {
        svg {
          fill: transparent;
        }
      }
    }
  }

  &_list {
    list-style: none;
    margin: 0;
    padding: 0;
    &_item {
      color: black;
      margin-bottom: 20px;
      background-color: #fff;
      padding: 2.23529rem;
      display: flex;
      flex-direction: column;
      border: 1px solid #dde2e6;
      border-radius: 4px;
    }
  }

  &_buttons {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
  }

  &_button {
    @extend .button;
    margin-bottom: 10px;
  }

  // &_add {
  //   @extend .button;
  // }

  // &_link {
  //   background: none;
  //   border: none;
  //   display: flex;
  //   padding: 0;
  //   line-height: 1.5;
  //   &:hover {
  //     text-decoration: underline;
  //     cursor: pointer;
  //   }
  // }
}
