html {
  scroll-behavior: smooth;
}

html,
body,
#root {
  height: 100%;
}
body {
  background-color: var(--c_background-site);
  color: var(--c_text-body);
  font-family: $ff_body;
}
a {
  color: var(--c_text-anchor);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
select {
  // A reset of styles, including removing the default dropdown arrow
  appearance: none;
  // Additional resets for further consistency
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
}
