@use 'src/styles/colors';
@use 'src/styles/variables';

.link-icon {
  --link-icon--icon-size: #{variables.$fs_medium_small};

  color: var(--link-icon--color, colors.$c_yellow);
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  line-height: 1;

  &:focus-visible {
    outline: solid colors.$c_text-anchor 2px;
  }

  svg {
    width: var(--link-icon--icon-size);
    height: var(--link-icon--icon-size);
  }

  &[data-size="small"] {
    --link-icon--icon-size: #{variables.$fs_small};
  }

  &[data-size="medium"] {
    --link-icon--icon-size: #{variables.$fs_medium_small};
  }

  &[data-size="large"] {
    --link-icon--icon-size: #{variables.$fs_medium_large};
  }

  &[data-size="dynamic"] {
    --link-icon--size: 1em;
  }
}
