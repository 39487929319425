  .overlay {
  $c_yellow: #5EBBFF;
  $c_concrete-shade-1: #1B4B6F;
  $c_grey-black: #082E4B;
  $c_crayfish: #FF634F;

  $overlay-z-index: 101;
  $c_error_background: $c_grey-black;
  $c_in_progress: $c_yellow;
  $c_done: #63744b;
  $c_error: $c_crayfish;
  $form_padding: 1.5rem;

  dialog {
    padding: 0;
  }

  &_wrapper {
    position: fixed;
    max-width: 50rem;
    height: fit-content;
    max-height: 80vh;
    background-color: $c_concrete-shade-1;
    color: white;
    overflow-y: auto;
    border: 0;
    z-index: $overlay-z-index;
  }

  &_wrapper::backdrop {
    position: fixed;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.65;
    width: 100vw;
    height: 100vh;
    z-index: calc(#{$overlay-z-index}-1);
  }

  &_form {
    padding: $form_padding;
  }

  &_status_snackbar {
    background-color: $c_error_background;
    padding: 2em 1em;
    font-weight: 500;
    width: 100%;
    display: block;

    &[data-status='loading'] {
      border-left: 8px solid $c_in_progress;

      svg {
        color: $c_in_progress;
      }
    }

    &[data-status='done'] {
      border-left: 8px solid $c_done;

      svg {
        color: $c_done;
      }
    }

    &[data-status='error'] {
      border-left: 8px solid $c_error;
      width: 100%;

      svg {
        color: $c_error;
      }
    }

    svg {
      margin-right: 1em;
      width: 1.25em;
      height: 1.25em;
    }
  }

  &_progressbar {
    height: 0%;
    width: 0%;
    height: 0.5em;

    &[data-status='loading'] {
      background-color: $c_in_progress;
      height: 0.5em;
    }

    &[data-status='done'] {
      background-color: $c_done;
      height: 0.5em;
      width: 100%;
    }

    &[data-status='error'] {
      background-color: $c_error;
      width: 100%;
    }
  }
}
