@use "src/styles/variables";
@use "src/styles/colors";

.list-item {
  padding: var(--list-item--padding, variables.$spacing-xxs);
  display: flex;
  gap: var(--list-item--gap, variables.$spacing-xs);
  justify-content: space-between;
  align-items: flex-start;

  &:not(:last-child) {
    border-bottom: var(--list-item--border, 1px solid colors.$c_background-site);
  }
}
