.Loader {
  width: var(--loader--width, 100%);
  height: var(--loader--height, 100%);

  &[data-variant="inline"] {
    --loader--width: 1em;
    --loader--height: 1em;
    --loader--inner-padding: 0;
    --loader--spinner-size: 1em;
    --loader--inner-display: inline-flex;
  }

  .inner {
    display: var(--loader--inner-display, flex);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: var(--loader--inner-padding, 1rem);
  }

  .spinner {
    width: var(--loader--spinner-size, 46px);
    height: var(--loader--spinner-size, 46px);
    background-color: $c_yellow;
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
  }

  span {
    font-weight: 900;
    color: $c_white;
    margin-top: 24px;
    margin-left: 4px;
  }
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
