.guide-export {
  display: flex;
  align-items: center;
  color: $c_cement-default;
  background-color: transparent;
  border: none;
  font-size: $fs_medium;
  cursor: pointer;

  .icon-download {
    width: 24px;
    color: $c_white;
    margin-right: $spacing-xxs;

    svg {
      width: 100%;
      height: auto;
    }
  }
}
