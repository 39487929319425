@use 'src/styles/colors';
@use 'src/styles/variables';

.button-link {
  color: var(--button-link--color, colors.$c_text-anchor);
  padding: 2px;
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.5em;
  font-family: variables.$ff_body;
  font-size: var(--button-link--font-size, variables.$fs_medium_small);
  line-height: 1;

  svg {
    min-width: 1.125em;
    height: 1.125em;
  }

  &[data-size='small'] {
    --button-link--font-size: #{variables.$fs_small};
  }

  &[data-size='medium'] {
    --button-link--font-size: #{variables.$fs_medium_small};
  }

  &[data-size='large'] {
    --button-link--font-size: #{variables.$fs_medium_large};
  }

  &[data-size='dynamic'] {
    --button-link--font-size: 1em;
  }

  &[data-variant='link'] {
    --button-link--color: #{colors.$c_cyan-0};
    &:hover {
      color: #{colors.$c_cyan-1};
    }

    &:disabled {
      color: #{colors.$c_cyan-2};
    }
    &:focus-visible {
      outline: solid #{colors.$c_cyan-0} 2px;
    }
  }

  &[data-variant='neutral'] {
    --button-link--color: #{colors.$c_text-body};
  }

  &[data-variant='signal'] {
    --button-link--color: #{colors.$c_accent-signal};
  }

  &[data-variant='action'] {
    --button-link--color: #{colors.$c_accent-action};
  }

  &[data-variant='dynamic'] {
    --button-link--color: currentColor;
  }

  &[data-state='idle'] {
    cursor: progress;
    --button-link--color--hover: var(--button-link--color);
  }

  &[disabled] {
    --button-link--color--hover: $c_concrete-default;
    --button-link--color: $c_concrete-default;
    opacity: 0.8;
    cursor: auto;
  }
  &[data-alignment='left'] {
    justify-content: start;
  }
  &[data-alignment='center'] {
    justify-content: center;
  }
  &[data-alignment='right'] {
    justify-content: end;
  }
}
