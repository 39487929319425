body {
  background-color: var(--c_background-site);
  color: var(--c_text-body);
  font-family: $ff_body;
}

.main {
  min-height: 100vh;
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  &-container {
    padding-top: $header-height;
    width: 100%;

    & > div:not(.guide-viewer-section) {
      @include container;
    }
  }

  &-container-login {
    @include container;
    padding-top: $header-height;
    text-align: center;
  }

  &-center {
    grid-template-columns: unset;
    align-items: center;
  }

  &-unauthorized {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    button {
      @include button-factory;
    }
  }
}
