@use "src/styles/colors";

.certified-badge {
  color: colors.$c_white;
  background-color: colors.$c_grey-medium;
  height: fit-content;
  .text {
    font-size: 13px;
  }
}
