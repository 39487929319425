@import "src/components/NavList/NavList";

.DocEditorNav {
  @include NavList;

  &_wrapper {
    margin-bottom: 2rem;
  }
  &_link {
    // display: flex;
    padding: 0 0 0 1rem;
  }
  &_label {
    flex-grow: 1;
    white-space: normal;
    line-height: 1.2;
    padding: 0.5em 0;
  }
  &_button-move {
    display: inline-flex;
    background-color: transparent;
    border: none;
    color: $c_button-list_text;
    padding: 1rem;
    min-width: 48px;

    &:hover,
    &:focus {
      cursor: n-resize;
      color: $c_button-list_background--hover;
      background-color: $c_button-list_text--hover;
    }
    &:active,
    [data-active="true"] > & {
      color: $c_button-list_text--active;
      background-color: $c_button-list_background--active;
    }
    &:disabled {
      color: $c_button-list_text--disabled;
      &:hover {
        cursor: default;
        background-color: transparent;
      }
    }
    &:first-child {
      // margin-left: auto;
    }
    &:last-child:hover {
      cursor: s-resize;
    }
  }
  &_add-button {
    @include button-factory-secondary();
    padding-left: 0.5em;
    padding-right: 0.5em;
    width: 100%;
    svg {
      order: 2;
      margin-left: 1em;
      margin-right: 0;
    }
  }
}
