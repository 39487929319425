.my-favourites {
  &_header_wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: $spacing-l;
  }

  &_button {
    color: $c_white;
    font-size: $fs_medium_small;
    font-weight: $fw_normal;
    background-color: transparent;
    border: none;
  }

  &_list_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $spacing-s;
    width: 100%;

    &:not([data-is-expanded="true"]) {
      > *:nth-child(n + 5) {
        display: none;
      }
    }
  }

  &_button_show_more {
    background-color: transparent;
    color: $c_white;
    width: 100%;
    border: 2px solid $c_white;
    margin-top: $spacing-m;
  }

  &_button_text {
    color: $c_white;
    font-size: $fs_medium_small;
    font-weight: $fw_normal;
    margin: $spacing-xxs 0;
  }
}
