@use "sass:math";

@mixin DocEditor {
  &_block {
    margin-bottom: 0.88em;
  }
  &_block_header-bar {
    display: flex;
    background-color: grey;
    flex-wrap: wrap;
    &:after {
      order: 3;
      align-self: center;
    }
  }
  &_block_header-bar_title {
    text-align: left;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &_block_header-bar_buttons {
    display: flex;
    flex-basis: 100%;
    justify-content: flex-end;
    align-items: center;
    padding: 1.19em 1.5em;
    background-color: $c_blue-1;
  }
  &_section {
    &-form {
      background-color: $c_togglebar_background;
      padding: 1.31em;
    }
  }
  .module-selector {
    padding: 1.5em 2em;
    background-color: $c_blue-1;

    &[data-depth="1"] {
      padding: 1.5em 0;
      background-color: $c_background-site;
    }
    &[data-depth="3"] {
      padding: 1.31em;
      .module-selector_module-list {
        align-items: flex-start;
      }
    }

    &_overlay,
    &_toggle-button {
      display: none; // These aren't needed in this project.
    }

    &_module {
      @include button-factory-secondary(
        $background-color-hover: transparent,
        $background-color-focus: transparent,
        $background-color-active: transparent
      );
      min-width: 13em;
      background: transparent;
      [data-depth="3"] > &-list {
        align-items: flex-start;
        padding: 0;
        .module-selector_module {
          min-width: 23em;
        }
      }

      &-list {
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 1em;
      }

      &_icon {
        margin-left: 1em;
        margin-right: 0;
        display: none;
      }
    }
  }
}

.DocEditor {
  @include DocEditor;
}
