@mixin choice-group(
  $color: $c_choice_text,
  $color-hover: $c_choice_text--hover,
  $color-focus: $c_choice_text--focus,
  $color-active: $c_choice_text--active,
  $color-disabled: $c_choice_text--disabled,
  $font-size: $fs_base,
  $border: 2px solid,
  $border-color: $c_choice_border,
  $border-color-hover: $c_choice_border--hover,
  $border-color-focus: $c_choice_border--focus,
  $border-color-active: $c_choice_border--active,
  $border-color-disabled: $c_choice_border--disabled,
  $background-color: $c_choice_background,
  $background-color-checked: $c_choice_background--checked,
  $background-color-disabled: $c_choice_background--disabled
) {
  &[role="radiogroup"] {
    display: flex;
  }
  &_label {
    display: flex;
    color: $color;
    font-size: $font-size;
    line-height: 1.166em;
    padding: 2px;
    align-items: center;
    justify-content: space-between;

    &[data-inversed="true"] {
      flex-direction: row-reverse;
      input {
        margin-right: 0;
        margin-left: 5px;
      }
    }

    &[data-disabled="true"] {
      color: $color-disabled;
    }

    &:hover {
      cursor: pointer;
      &[disabled] {
        cursor: not-allowed;
      }
    }

    &:focus-within {
      border: $border $border-color-focus;
    }

    input {
      box-sizing: border-box;
      appearance: none;
      min-width: 24px;
      min-height: 24px;
      border: $border;
      border-color: $border-color;
      background-color: $background-color;
      outline: none;
      cursor: pointer;
      margin-right: 5px;
      &:hover {
        border-color: $border-color-hover;
      }
      &:disabled {
        cursor: not-allowed;
        border-color: $border-color-disabled;
      }
    }

    input[type="checkbox"]:checked {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='11' viewBox='0 0 12 11'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23f6f8f9' stroke-linecap='square' stroke-width='2' d='M1.5 7.05L4.071 9.75 10.5 3' transform='translate(0 -1)' /%3E%3C/svg%3E");
      background-size: 18px 18px;
      background-repeat: no-repeat;
      background-position: center;
    }

    input[type="checkbox"]:checked:disabled {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='11' viewBox='0 0 12 11'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23b0c0c8' stroke-linecap='square' stroke-width='2' d='M1.5 7.05L4.071 9.75 10.5 3' transform='translate(0 -1)' /%3E%3C/svg%3E");
      cursor: not-allowed;
    }

    input[type="radio"] {
      border-radius: 50%;
      border-width: 2px;
      &:hover {
        border-color: $c_blue-5;
        background-color: $c_blue-4;
        background-image: radial-gradient(
          circle at center,
          $c_blue-2 0%, 
          $c_blue-2 43%, 
          transparent 43%, 
          transparent 100%
        );
      }
      &:checked {
        border-color: $background-color-checked;
        background-color: $background-color-checked;
        background-image: radial-gradient(
          circle at center,
          $c_blue-1 0%, 
          $c_blue-1 43%, 
          transparent 43%, 
          transparent 100%
        );
      }
      &:disabled {
        background-color: $background-color-disabled;
        cursor: not-allowed;
      }
    }
  }
  &_text {
    width: fit-content;
  }
}

@mixin setup-choice-group($ns: "choice-group") {
  .#{$ns} {
    @include choice-group();
  }
}

@include setup-choice-group();
