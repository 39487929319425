.guide-viewer-section-buttons {
  display: flex;
  justify-content: space-between;

  [data-introduction="true"] & {
    margin-top: 4rem;
  }

  &[data-flex="start"] {
    justify-content: flex-start;
  }

  &[data-flex="end"] {
    justify-content: flex-end;
  }

  &_button {
    @include button-factory-squareicon("M");
    &_sublabel {
      font-size: $fs_small;
    }
  }
}
