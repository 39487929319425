.guides {
  margin-top: $spacing-m;
  &_title {
  }

  &_list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 30px;
    &_item {
      @extend .table;
      grid-template-columns: 3fr 3fr 1fr 1fr;
      grid-gap: $spacing-xxs;
      align-items: center;

      .icon {
        margin-right: 5px;
        margin-bottom: 4px;
      }
    }
  }

  &_tools {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &[data-align='center'] {
      justify-content: center;
    }
    &[data-align='right'] {
      justify-content: flex-end;
    }
  }

  &_actions {
    display: flex;
    margin-top: 10px;
  }

  &_button {
    @extend .button-inverted;
    flex: 1;

    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }

  &_add {
    @extend .button;
    margin-bottom: $gutter;
  }

  &_link {
    background: none;
    border: none;
    display: inline;
    padding: 0;
    line-height: 1.5;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &_instances {
    margin-bottom: $spacing-xs;
    max-height: 50vh;
    overflow: auto;

    &_instance {
      font-size: $fs_medium_small;
      margin-bottom: $spacing-xxxs;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }
}
