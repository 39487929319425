.sub-header {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $c_bg_dark;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    min-height: inherit;
    left: 0;
  }
  &_wrapper {
    @include container;
    position: relative;
    padding: 3.333rem 0;

    h1 {
      @extend .hero;
    }

    h2 {
      @include header($fs_2xlarge);
    }

    h3 {
      @include header($fs_xlarge);
    }

    p {
      @extend .hero-description;
      white-space: pre-line; // TODO: Remove when a text editor is implented for article ingress.
    }
  }

  &_start {
    background-color: transparent;
  }
}
