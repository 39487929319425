.main-header {
  position: fixed;
  z-index: 1000;
  width: 100%;
  background-color: var(--c_background-site);
  border-bottom: solid 1px $c_bg_dark;
  &_container {
    @include container;
    display: flex;
    justify-content: space-between;
    padding: 0 18px;
    min-height: $header-height;
  }
  &_main-nav {
    display: flex;
  }
  &_menu_container {
    display: flex;
    flex-direction: row;
    z-index: 100;
  }
  &_logo {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: $spacing-xs;
    color: $c_white;
    &:hover {
      text-decoration: none;
    }
  }
}
