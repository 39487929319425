@use 'src/styles/variables';
@use 'src/styles/colors';

.list {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: var(--list--gap, 2px);
}
