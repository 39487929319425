@mixin search($ns: "search") {
  .#{$ns} {
    display: flex;
    margin-top: $spacing-m;
    margin-bottom: $spacing-m;
  }
  .#{$ns}_input {
    font-size: 21px;
    line-height: 1.44;
    padding: 19px 75px 19px 24px;
    border: none;
    background-color: $c_white;
    color: $c_grey-black;
    width: 100%;
    height: 72px;

    &:focus {
      outline: none;
    }
  }

  .#{$ns}_submit {
    @extend .button-inverted;
  }

  .#{$ns}_icon {
    &_holder {
      background-color: $c_blue-1;
      width: 72px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    width: 24px;
    color: $c_white;

    svg {
      width: 100%;
      height: auto;
    }
  }

  .#{$ns}[data-compact="true"] {
    margin-block: 0;
    .#{$ns}_input {
      padding: 12px;
      border: none;
      border-bottom: 2px solid $c_yellow;
    }
  }
}

@include search();
