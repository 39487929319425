.list-box {
  &_header {
    background-color: $c_bg_light;
    padding: $spacing-s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $spacing-xs;
  }

  &_title {
    margin: 0;
  }

  &_content {
    max-height: var(--list-box--max-height);
    overflow: auto;
  }

  &_items {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
}
