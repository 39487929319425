/**
 * Base button mixin which will superceed the current .button class
 */
@mixin button-factory(
  $size: "MS",
  $color: $c_button_text,
  $color-hover: $c_button_text--hover,
  $color-focus: $c_button_text--focus,
  $color-active: $c_button_text--active,
  $color-disabled: $c_button_text--disabled,
  $background-color: $c_button_background,
  $background-color-hover: $c_button_background--hover,
  $background-color-focus: $c_button_background--focus,
  $background-color-active: $c_button_background--active,
  $background-color-disabled: $c_button_background--disabled,
  $border: "2px solid" $c_button_border,
  $border-color-hover: $c_button_border--hover,
  $border-color-focus: $c_button_border--focus,
  $border-color-active: $c_button_border--active,
  $border-color-disabled: $c_button_border--disabled,
  $icon-color: $c_button_text,
  $icon-color-hover: $c_button_text--hover,
  $icon-color-focus: $c_button_text--focus,
  $icon-color-active: $c_button_text--active,
  $icon-color-disabled: $c_button_text--disabled,
  $border-radius: $border_radius,
  $padding: "1em 2.5em",
  $font-size: $fs_base,
  $font-weight: $fw_semibold,
  $icon-position: "none",
  $outline-color: $c_button_outline
) {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: #{$padding};
  color: $color;
  background: $background-color;
  border: #{$border};
  border-radius: $border-radius;
  font-weight: $font-weight;
  white-space: nowrap;
  text-align: center;
  line-height: $lh_base;
  text-decoration: none;

  @if $size == "XXS" {
    font-size: $fs_2xsmall;
  } @else if $size == "XS" {
    font-size: $fs_xsmall;
  } @else if $size == "S" {
    font-size: $fs_small;
  } @else if $size == "MS" {
    font-size: $fs_medium_small;
  } @else if $size == "M" {
    font-size: $fs_medium;
  } @else if $size == "ML" {
    font-size: $fs_medium_large;
  } @else if $size == "L" {
    font-size: $fs_large;
  } @else if $size == "XL" {
    font-size: $fs_xlarge;
  } @else {
    font-size: $font-size;
  }
  // An other way of changing size is placing a data attibute on the DOM element.
  &[data-size="XS"] {
    font-size: $fs_xsmall;
  }
  &[data-size="S"] {
    font-size: $fs_small;
  }
  &[data-size="MS"] {
    font-size: $fs_medium_small;
  }
  &[data-size="M"] {
    font-size: $fs_medium;
  }
  &[data-size="ML"] {
    font-size: $fs_medium_large;
  }
  &[data-size="L"] {
    font-size: $fs_large;
  }
  &[data-size="XL"] {
    font-size: $fs_xlarge;
  }
  &[data-size="XXL"] {
    font-size: $fs_2xlarge;
  }

  &::selection {
    background: transparent;
  }

  &:hover {
    cursor: pointer;
    color: $color-hover;
    border-color: $border-color-hover;
    background: $background-color-hover;
    text-decoration: none;
  }
  &:focus {
    color: $color-focus;
    border-color: $border-color-focus;
    background: $background-color-focus;
    box-shadow: 0 0 7px 0 $outline-color;

    svg {
      fill: $icon-color-focus;
      stroke: $icon-color-focus;
      color: $icon-color-focus;
    }
  }
  &[disabled],
  &[data-disabled="true"] {
    cursor: default;
    color: $color-disabled;
    border-color: $border-color-disabled;
    background: $background-color-disabled;

    svg {
      fill: $icon-color-disabled;
      stroke: $icon-color-disabled;
      color: $icon-color-disabled;
    }
  }

  &:active:not([disabled]),
  &[data-active="true"]:not([data-disabled="true"]) {
    color: $color-active;
    border-color: $border-color-active;
    background: $background-color-active;
    &:hover {
      cursor: default; // Useful when used in a menu context
    }
    svg {
      fill: $icon-color-active;
      stroke: $icon-color-active;
      color: $icon-color-active;
    }
  }

  .loading {
    position: relative;
    top: inherit;
    left: inherit;
    transform: none;
    min-width: auto;
    margin: 0 0.5em 0 0;
    padding: 0.9em;

    svg {
      .loading_ring {
        stroke: $background-color;
      }
      .loading_path {
        stroke: $icon-color;
      }
    }
  }

  &_icon,
  &_icon-left {
    margin-right: 1em;
  }
  &_icon-right {
    margin-left: 1em;
    margin-right: 0;
  }
  // WIP
  svg {
    @if $icon-position == "left" {
      margin-right: 1em;
    } @else if $icon-position == "right" {
      margin-left: 1em;
      margin-right: 0;
    } @else if $icon-position == "top" {
      margin-left: auto;
      margin-right: auto;
      flex-basis: 100%;
      flex-grow: 1;
    }
  }
}

@mixin setup-button($ns: "button") {
  .#{$ns} {
    @include button-factory();
  }
}
@include setup-button();

@mixin button() {
  @include button-factory();
}

/**
 * Base button mixin which will superceed the current .button-inverted class
 */
@mixin button-factory-inverted(
  $size: "MS",
  $color: $c_button-inverted_text,
  $color-hover: $c_button-inverted_text--hover,
  $color-focus: $c_button-inverted_text--focus,
  $color-active: $c_button-inverted_text--active,
  $color-disabled: $c_button-inverted_text--disabled,
  $background-color: $c_button-inverted_background,
  $background-color-hover: $c_button-inverted_background--hover,
  $background-color-focus: $c_button-inverted_background--focus,
  $background-color-active: $c_button-inverted_background--active,
  $background-color-disabled: $c_button-inverted_background--disabled,
  $border: "2px solid" $c_button-inverted_border,
  $border-color-hover: $c_button-inverted_border--hover,
  $border-color-focus: $c_button-inverted_border--focus,
  $border-color-active: $c_button-inverted_border--active,
  $border-color-disabled: $c_button-inverted_border--disabled,
  $icon-color: $c_button-inverted_text,
  $icon-color-hover: $c_button-inverted_text--hover,
  $icon-color-focus: $c_button-inverted_text--focus,
  $icon-color-active: $c_button-inverted_text--active,
  $icon-color-disabled: $c_button-inverted_text--disabled,
  $border-radius: $border_radius,
  $padding: "1em 2.5em",
  $font-size: $fs_base,
  $font-weight: $fw_semibold,
  $icon-position: "none",
  $outline-color: $c_button-inverted_outline
) {
  @include button-factory(
    $size,
    $color,
    $color-hover,
    $color-focus,
    $color-active,
    $color-disabled,
    $background-color,
    $background-color-hover,
    $background-color-focus,
    $background-color-active,
    $background-color-disabled,
    $border,
    $border-color-hover,
    $border-color-focus,
    $border-color-active,
    $border-color-disabled,
    $icon-color,
    $icon-color-hover,
    $icon-color-focus,
    $icon-color-active,
    $icon-color-disabled,
    $border-radius,
    $padding,
    $font-size,
    $font-weight,
    $icon-position,
    $outline-color
  );
}

@mixin setup-button-inverted($ns: "button-inverted") {
  .#{$ns} {
    @include button-factory-inverted();
  }
}
@include setup-button-inverted();

/**
 * Base button mixin which will superceed the current .button-secondary class
 */
@mixin button-factory-secondary(
  $size: "MS",
  $color: $c_button-secondary_text,
  $color-hover: $c_button-secondary_text--hover,
  $color-focus: $c_button-secondary_text--focus,
  $color-active: $c_button-secondary_text--active,
  $color-disabled: $c_button-secondary_text--disabled,
  $background-color: $c_button-secondary_background,
  $background-color-hover: $c_button-secondary_background--hover,
  $background-color-focus: $c_button-secondary_background--focus,
  $background-color-active: $c_button-secondary_background--active,
  $background-color-disabled: $c_button-secondary_background--disabled,
  $border: "2px solid" $c_button-secondary_border,
  $border-color-hover: $c_button-secondary_border--hover,
  $border-color-focus: $c_button-secondary_border--focus,
  $border-color-active: $c_button-secondary_border--active,
  $border-color-disabled: $c_button-secondary_border--disabled,
  $icon-color: $c_button-secondary_text,
  $icon-color-hover: $c_button-secondary_text--hover,
  $icon-color-focus: $c_button-secondary_text--focus,
  $icon-color-active: $c_button-secondary_text--active,
  $icon-color-disabled: $c_button-secondary_text--disabled,
  $border-radius: $border_radius,
  $padding: "1em 2.5em",
  $font-size: $fs_base,
  $font-weight: $fw_semibold,
  $icon-position: "none",
  $outline-color: $c_button-secondary_outline
) {
  @include button-factory(
    $size,
    $color,
    $color-hover,
    $color-focus,
    $color-active,
    $color-disabled,
    $background-color,
    $background-color-hover,
    $background-color-focus,
    $background-color-active,
    $background-color-disabled,
    $border,
    $border-color-hover,
    $border-color-focus,
    $border-color-active,
    $border-color-disabled,
    $icon-color,
    $icon-color-hover,
    $icon-color-focus,
    $icon-color-active,
    $icon-color-disabled,
    $border-radius,
    $padding,
    $font-size,
    $font-weight,
    $icon-position,
    $outline-color
  );
}

@mixin setup-button-secondary($ns: "button-secondary") {
  .#{$ns} {
    @include button-factory-secondary();
  }
}
@include setup-button-secondary();

/**
 * Base button mixin which will superceed the current .button-secondary class
 */
@mixin button-factory-secondary-inverted(
  $size: "MS",
  $color: $c_button-secondary-inverted_text,
  $color-hover: $c_button-secondary-inverted_text--hover,
  $color-focus: $c_button-secondary-inverted_text--focus,
  $color-active: $c_button-secondary-inverted_text--active,
  $color-disabled: $c_button-secondary-inverted_text--disabled,
  $background-color: $c_button-secondary-inverted_background,
  $background-color-hover: $c_button-secondary-inverted_background--hover,
  $background-color-focus: $c_button-secondary-inverted_background--focus,
  $background-color-active: $c_button-secondary-inverted_background--active,
  $background-color-disabled: $c_button-secondary-inverted_background--disabled,
  $border: "2px solid" $c_button-secondary-inverted_border,
  $border-color-hover: $c_button-secondary-inverted_border--hover,
  $border-color-focus: $c_button-secondary-inverted_border--focus,
  $border-color-active: $c_button-secondary-inverted_border--active,
  $border-color-disabled: $c_button-secondary-inverted_border--disabled,
  $icon-color: $c_button-secondary-inverted_text,
  $icon-color-hover: $c_button-secondary-inverted_text--hover,
  $icon-color-focus: $c_button-secondary-inverted_text--focus,
  $icon-color-active: $c_button-secondary-inverted_text--active,
  $icon-color-disabled: $c_button-secondary-inverted_text--disabled,
  $border-radius: $border_radius,
  $padding: "1em 2.5em",
  $font-size: $fs_base,
  $font-weight: $fw_semibold,
  $icon-position: "none",
  $outline-color: $c_button-secondary-inverted_outline
) {
  @include button-factory(
    $size,
    $color,
    $color-hover,
    $color-focus,
    $color-active,
    $color-disabled,
    $background-color,
    $background-color-hover,
    $background-color-focus,
    $background-color-active,
    $background-color-disabled,
    $border,
    $border-color-hover,
    $border-color-focus,
    $border-color-active,
    $border-color-disabled,
    $icon-color,
    $icon-color-hover,
    $icon-color-focus,
    $icon-color-active,
    $icon-color-disabled,
    $border-radius,
    $padding,
    $font-size,
    $font-weight,
    $icon-position,
    $outline-color
  );
}

@mixin setup-button-secondary-inverted($ns: "button-secondary-inverted") {
  .#{$ns} {
    @include button-factory-secondary-inverted();
  }
}
@include setup-button-secondary-inverted();

/**
 * Base button mixin which will is used for deletion  and other actions which requires attention
 */
@mixin button-factory-warning(
  $size: "MS",
  $color: $c_button-warning_text,
  $color-hover: $c_button-warning_text--hover,
  $color-focus: $c_button-warning_text--focus,
  $color-active: $c_button-warning_text--active,
  $color-disabled: $c_button-warning_text--disabled,
  $background-color: $c_button-warning_background,
  $background-color-hover: $c_button-warning_background--hover,
  $background-color-focus: $c_button-warning_background--focus,
  $background-color-active: $c_button-warning_background--active,
  $background-color-disabled: $c_button-warning_background--disabled,
  $border: "2px solid" $c_button-warning_border,
  $border-color-hover: $c_button-warning_border--hover,
  $border-color-focus: $c_button-warning_border--focus,
  $border-color-active: $c_button-warning_border--active,
  $border-color-disabled: $c_button-warning_border--disabled,
  $icon-color: $c_button-warning_text,
  $icon-color-hover: $c_button-warning_text--hover,
  $icon-color-focus: $c_button-warning_text--focus,
  $icon-color-active: $c_button-warning_text--active,
  $icon-color-disabled: $c_button-warning_text--disabled
) {
  @include button-factory(
    $size,
    $color,
    $color-hover,
    $color-focus,
    $color-active,
    $color-disabled,
    $background-color,
    $background-color-hover,
    $background-color-focus,
    $background-color-active,
    $background-color-disabled,
    $border,
    $border-color-hover,
    $border-color-focus,
    $border-color-active,
    $border-color-disabled,
    $icon-color,
    $icon-color-hover,
    $icon-color-focus,
    $icon-color-active,
    $icon-color-disabled
  );
}

@mixin setup-button-warning($ns: "button-warning") {
  .#{$ns} {
    @include button-factory-warning();
  }
}
@include setup-button-warning();

/**
 * Buttons used in selectors and dropdowns
 */
@mixin button-factory-select(
  $ns: "button-select",
  $size: "MS",
  $color: $c_button-select_text,
  $color-hover: $c_button-select_text--hover,
  $color-focus: $c_button-select_text--focus,
  $color-active: $c_button-select_text--active,
  $color-disabled: $c_button-select_text--disabled,
  $background-color: $c_button-select_background,
  $background-color-hover: $c_button-select_background--hover,
  $background-color-focus: $c_button-select_background--focus,
  $background-color-active: $c_button-select_background--active,
  $background-color-disabled: $c_button-select_background--disabled,
  $border: "2px solid" $c_button-select_border,
  $border-color-hover: $c_button-select_border--hover,
  $border-color-focus: $c_button-select_border--focus,
  $border-color-active: $c_button-select_border--active,
  $border-color-disabled: $c_button-select_border--disabled,
  $icon-color: $c_button-select_icon,
  $icon-color-hover: $c_button-select_icon--hover,
  $icon-color-focus: $c_button-select_icon--focus,
  $icon-color-active: $c_button-select_icon--active,
  $icon-color-disabled: $c_button-select_icon--disabled,
  $border-radius: $border_radius,
  $padding: "0.666em 0 0.666em 1.333em"
) {
  @include button-factory(
    $size,
    $color,
    $color-hover,
    $color-focus,
    $color-active,
    $color-disabled,
    $background-color,
    $background-color-hover,
    $background-color-focus,
    $background-color-active,
    $background-color-disabled,
    "none",
    $border-color-hover,
    $border-color-focus,
    $border-color-active,
    $border-color-disabled,
    $icon-color,
    $icon-color-hover,
    $icon-color-focus,
    $icon-color-active,
    $icon-color-disabled,
    $border-radius,
    $padding
  );
  justify-content: space-between;

  .#{$ns}_label {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .#{$ns}_icon {
    margin: 0 1.333em 0 0.5em;
  }
}

@mixin setup-button-select($ns: "button-select") {
  .#{$ns} {
    @include button-factory-select($ns);
  }
}
@include setup-button-select();

@mixin button-select($ns: "button-select") {
  @include button-factory-select($ns);
}

/**
 * Buttons used in selectors and dropdowns
 */
@mixin button-factory-list(
  $size: "MS",
  $color: $c_button-list_text,
  $color-hover: $c_button-list_text--hover,
  $color-focus: $c_button-list_text--focus,
  $color-active: $c_button-list_text--active,
  $color-disabled: $c_button-list_text--disabled,
  $background-color: $c_button-list_background,
  $background-color-hover: $c_button-list_background--hover,
  $background-color-focus: $c_button-list_background--focus,
  $background-color-active: $c_button-list_background--active,
  $background-color-disabled: $c_button-list_background--disabled,
  $border: "1px solid" $c_button-list_border,
  $border-color-hover: $c_button-list_border--hover,
  $border-color-focus: $c_button-list_border--focus,
  $border-color-active: $c_button-list_border--active,
  $border-color-disabled: $c_button-list_border--disabled,
  $icon-color: $c_button-list_icon,
  $icon-color-hover: $c_button-list_icon--hover,
  $icon-color-focus: $c_button-list_icon--focus,
  $icon-color-active: $c_button-list_icon--active,
  $icon-color-disabled: $c_button-list_icon--disabled,
  $border-radius: $border_radius,
  $padding: "0.666em 1.333em"
) {
  @include button-factory(
    $size,
    $color,
    $color-hover,
    $color-focus,
    $color-active,
    $color-disabled,
    $background-color,
    $background-color-hover,
    $background-color-focus,
    $background-color-active,
    $background-color-disabled,
    $border,
    $border-color-hover,
    $border-color-focus,
    $border-color-active,
    $border-color-disabled,
    $icon-color,
    $icon-color-hover,
    $icon-color-focus,
    $icon-color-active,
    $icon-color-disabled,
    $border-radius,
    $padding
  );

  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  text-align: left;
  font-weight: 500;
  border-top: none;
  border-left: none;
  border-right: none;
  // TODO: continue with rules for icon and icon background for various states.
}

@mixin setup-button-list($ns: "button-list") {
  .#{$ns} {
    @include button-factory-list();
  }
}
@include setup-button-list();
@mixin button-list {
  @include button-factory-list();
}

/**
 * Base button mixin which will is used for deletion  and other actions which requires attention
 */
@mixin button-factory-squareicon(
  $size: "MS",
  $color: $c_button-squareicon_text,
  $color-hover: $c_button-squareicon_text--hover,
  $color-focus: $c_button-squareicon_text--focus,
  $color-active: $c_button-squareicon_text--active,
  $color-disabled: $c_button-squareicon_text--disabled,
  $background-color: $c_button-squareicon_background,
  $background-color-hover: $c_button-squareicon_background--hover,
  $background-color-focus: $c_button-squareicon_background--focus,
  $background-color-active: $c_button-squareicon_background--active,
  $background-color-disabled: $c_button-squareicon_background--disabled,
  $border: "2px solid" $c_button-squareicon_border,
  $border-color-hover: $c_button-squareicon_border--hover,
  $border-color-focus: $c_button-squareicon_border--focus,
  $border-color-active: $c_button-squareicon_border--active,
  $border-color-disabled: $c_button-squareicon_border--disabled,
  $icon-color: $c_button-squareicon_icon,
  $icon-color-hover: $c_button-squareicon_icon--hover,
  $icon-color-focus: $c_button-squareicon_icon--focus,
  $icon-color-active: $c_button-squareicon_icon--active,
  $icon-color-disabled: $c_button-squareicon_icon--disabled,
  $icon-background: $c_button-squareicon_icon-background,
  $icon-background-hover: $c_button-squareicon_icon-background--hover,
  $icon-background-focus: $c_button-squareicon_icon-background--focus,
  $icon-background-active: $c_button-squareicon_icon-background--active,
  $icon-background-disabled: $c_button-squareicon_icon-background--disabled,
  $border-radius: $border_radius,
  $padding: "1em 2.5em"
) {
  @include button-factory(
    $size,
    $color,
    $color-hover,
    $color-focus,
    $color-active,
    $color-disabled,
    $background-color,
    $background-color-hover,
    $background-color-focus,
    $background-color-active,
    $background-color-disabled,
    "none",
    $border-color-hover,
    $border-color-focus,
    $border-color-active,
    $border-color-disabled,
    $icon-color,
    $icon-color-hover,
    $icon-color-focus,
    $icon-color-active,
    $icon-color-disabled,
    $border-radius,
    0
  );
  $icon-area: 60px;
  @if $size == "XXS" {
    $icon-area: 24px;
  } @else if $size == "XS" {
    $icon-area: 40px;
  } @else if $size == "S" {
    $icon-area: 60px; // Not used
  } @else if $size == "MS" {
    $icon-area: 60px;
  } @else if $size == "M" {
    $icon-area: 98px;
  } @else if $size == "ML" {
    $icon-area: 98px; // Not used
  } @else if $size == "L" {
    $icon-area: 98px; // Not used
  } @else if $size == "XL" {
    $icon-area: 98px; // Not used
  } @else {
    $icon-area: 60px;
  }
  &_icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: $icon-background;
    width: $icon-area;
    min-height: $icon-area;
    height: 100%;
    margin-right: 0;
  }
  &:hover &_icon {
    background: $icon-background-hover;
  }
  &:focus &_icon {
    background: $icon-background-focus;
  }
  &:active &_icon {
    background: $icon-background-active;
  }
  &:disabled &_icon {
    background: $icon-background-disabled;
  }
  &_label {
    padding: #{$padding};
    border: #{$border};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &:hover &_label {
    border-color: $border-color-hover;
  }
  &:focus &_label {
    border-color: $border-color-focus;
  }
  &:active &_label {
    border-color: $border-color-active;
  }
  &:disabled &_label {
    border-color: $border-color-disabled;
  }
}

@mixin setup-button-squareicon($ns: "button-squareicon") {
  .#{$ns} {
    @include button-factory-squareicon();
  }
}
@include setup-button-squareicon();

/**
 * Base button mixin which will superceed the current .button-secondary class
 */
@mixin button-factory-guide-tool(
  $size: "XS",
  $color: $c_button-guide-tool_text,
  $color-hover: $c_button-guide-tool_text--hover,
  $color-focus: $c_button-guide-tool_text--focus,
  $color-active: $c_button-guide-tool_text--active,
  $color-disabled: $c_button-guide-tool_text--disabled,
  $background-color: $c_button-guide-tool_background,
  $background-color-hover: $c_button-guide-tool_background--hover,
  $background-color-focus: $c_button-guide-tool_background--focus,
  $background-color-active: $c_button-guide-tool_background--active,
  $background-color-disabled: $c_button-guide-tool_background--disabled,
  $border: "2px solid" $c_button-guide-tool_border,
  $border-color-hover: $c_button-guide-tool_border--hover,
  $border-color-focus: $c_button-guide-tool_border--focus,
  $border-color-active: $c_button-guide-tool_border--active,
  $border-color-disabled: $c_button-guide-tool_border--disabled,
  $icon-color: $c_button-guide-tool_icon,
  $icon-color-hover: $c_button-guide-tool_icon--hover,
  $icon-color-focus: $c_button-guide-tool_icon--focus,
  $icon-color-active: $c_button-guide-tool_icon--active,
  $icon-color-disabled: $c_button-guide-tool_icon--disabled,
  $border-radius: $border_radius,
  $padding: "1em 2.5em",
  $font-size: $fs_base,
  $font-weight: $fw_medium,
  $icon-position: "none",
  $outline-color: $c_button-guide-tool_outline
) {
  @include button-factory(
    $size,
    $color,
    $color-hover,
    $color-focus,
    $color-active,
    $color-disabled,
    $background-color,
    $background-color-hover,
    $background-color-focus,
    $background-color-active,
    $background-color-disabled,
    $border,
    $border-color-hover,
    $border-color-focus,
    $border-color-active,
    $border-color-disabled,
    $icon-color,
    $icon-color-hover,
    $icon-color-focus,
    $icon-color-active,
    $icon-color-disabled,
    $border-radius,
    $padding,
    $font-size,
    $font-weight,
    $icon-position,
    $outline-color
  );

  display: flex;
  flex-direction: column;

  svg {
    margin: 0;
    fill: transparent;
    color: $c_button-guide-tool_icon;
  }
  .tick {
    color: $c_concrete-default;
  }
  &:hover {
    svg {
      fill: transparent;
      color: $c_button-guide-tool_icon--hover;
    }
    .tick {
      color: $c_lime;
    }
  }
  &:focus {
    svg {
      fill: transparent;
      color: $c_button-guide-tool_icon--hover;
    }
    .tick {
      color: $c_button-guide-tool_icon--hover;
    }
  }
  &[data-checked="true"] {
    background-color: $c_lime;
    border-color: $c_lime;
    color: $c_grey-black;
    .tick {
      color: $c_grey-black;
    }
  }
}

@mixin setup-button-guide-tool($ns: "button-guide-tool") {
  .#{$ns} {
    @include button-factory-guide-tool();
  }
}
@include setup-button-guide-tool();

/**
 * Icons should implement fill="inherit" stroke="inherit"
 */
@mixin button-factory-iconbutton(
  $size: "MS",
  $color: $c_button-iconbutton_text,
  $color-hover: $c_button-iconbutton_text--hover,
  $color-focus: $c_button-iconbutton_text--focus,
  $color-active: $c_button-iconbutton_text--active,
  $color-active-hover: $c_button-iconbutton_text--active-hover,
  $color-disabled: $c_button-iconbutton_text--disabled,
  $background-color: $c_button-iconbutton_background,
  $background-color-hover: $c_button-iconbutton_background--hover,
  $background-color-focus: $c_button-iconbutton_background--focus,
  $background-color-active: $c_button-iconbutton_background--active,
  $background-color-disabled: $c_button-iconbutton_background--disabled,
  $border: "2px solid" $c_button-iconbutton_border,
  $border-color-hover: $c_button-iconbutton_border--hover,
  $border-color-focus: $c_button-iconbutton_border--focus,
  $border-color-active: $c_button-iconbutton_border--active,
  $border-color-disabled: $c_button-iconbutton_border--disabled,
  $icon-color: $c_button-iconbutton_icon,
  $icon-color-hover: $c_button-iconbutton_icon--hover,
  $icon-color-focus: $c_button-iconbutton_icon--focus,
  $icon-color-active: $c_button-iconbutton_icon--active,
  $icon-color-disabled: $c_button-iconbutton_icon--disabled,
  $icon-background: $c_button-iconbutton_icon-background,
  $icon-background-hover: $c_button-iconbutton_icon-background--hover,
  $icon-background-focus: $c_button-iconbutton_icon-background--focus,
  $icon-background-active: $c_button-iconbutton_icon-background--active,
  $icon-background-disabled: $c_button-iconbutton_icon-background--disabled,
  $border-radius: $border_radius,
  $padding: "1em 2.5em"
) {
  @include button-factory(
    $fs_xsmall,
    // Setting font-size to a single size
    $color,
    $color-hover,
    $color-focus,
    $color-active,
    $color-disabled,
    $background-color,
    $background-color-hover,
    $background-color-focus,
    $background-color-active,
    $background-color-disabled,
    "none",
    $border-color-hover,
    $border-color-focus,
    $border-color-active,
    $border-color-disabled,
    $icon-color,
    $icon-color-hover,
    $icon-color-focus,
    $icon-color-active,
    $icon-color-disabled,
    $border-radius,
    0,
    $fs_xsmall,
    $fw_semibold,
    "top"
  );
  svg {
    fill: none;
  }
  /* &:hover {
    &_icon,
    svg {
      fill: none;
      stroke: $icon-color-hover;
    }
  }
 */

  &[data-active="true"] {
    color: $color-active;
    fill: $icon-color-active;
    stroke: $icon-color-active;
    &:hover {
      cursor: pointer;
      color: $color-active-hover;
      fill: $icon-color-active;
      stroke: $icon-color-active;

      &_icon,
      svg {
        fill: $icon-color-active;
        stroke: $icon-color-active;
      }
    }
  }
  &[active]:hover &_icon,
  &[active]:hover svg,
  &[data-active="true"]:hover &_icon,
  &[data-active="true"]:hover svg {
    fill: $icon-color-active;
    stroke: $icon-color-active;
  }
  //
  &:not([data-notification="true"]) {
    svg path {
      clip-path: none;
    }
    svg #indicator {
      fill: transparent;
    }
  }
  &[data-notification="true"] {
    svg #indicator {
      fill: $c_accent-action;
    }
  }
  //
  &[disabled],
  &[data-disabled="true"] {
    fill: $c_button-iconbutton_icon--disabled;
    stroke: $c_button-iconbutton_icon--disabled;

    &:hover {
      &_icon,
      svg {
        fill: $c_button-iconbutton_icon--disabled;
        stroke: $c_button-iconbutton_icon--disabled;
      }
    }
  }
}

@mixin setup-iconbutton($ns: "button-iconbutton") {
  .#{$ns} {
    @include button-factory-iconbutton();
  }
}
@include setup-iconbutton();

@mixin button-row() {
  display: flex;
  justify-content: space-between;
  gap: $spacing-s;

  & > * {
    flex: 1;
  }
}

@mixin setup-buttonrow($ns: "button-row") {
  .#{$ns} {
    @include button-row();
  }
}
@include setup-buttonrow();
