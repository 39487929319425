.menu-component {
  background-color: $c_bg_darkest;
  line-height: 21px;
  color: $c_bg_light; //arrow
  min-width: 200px;
  padding-right: 18px;
  &:hover {
    color: $c_white;
    cursor: pointer;
  }
  &_menu_button {
    display: flex;
    align-items: center;

    .icon-chevron {
      transition: transform 0.2s ease;
      width: 12px;
      margin-left: 12px;

      svg {
        width: 100%;
        height: auto;
      }
    }

    &[aria-expanded="true"] {
      color: $c_white;
      position: relative;
      .icon-chevron {
        transform: rotate(180deg) translate(0px, -3px);
      }

      &::after {
        border: inset 22px;
        content: "";
        display: block;
        height: 0px;
        width: 0px;
        border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $c_white rgba(0, 0, 0, 0);
        border-bottom-style: solid;
        position: absolute;
        bottom: -1px;
        left: 50%;
        transform: translateY(28px);
        z-index: 89;
      }
    }

    & .menu-component_linktext {
      color: $c_white;
    }
  }

  &_primary_arrow {
    height: $header-height;
  }
  &_pretitle {
    text-transform: uppercase;
    font-size: 12px;
    padding: 5px 10px;
    display: block;
    color: $c_grey-black;
    opacity: 0.5;
    border-bottom: 1px solid $c_menu_border;
  }
  &_linktext {
    margin-left: 15px;
    color: $c_grey-black;
    font-family: inherit;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: left;
    position: relative;
    max-width: 15.625rem;
    white-space: nowrap;
    .disabled & {
      color: $c_text--disabled;
    }
  }
  &_title {
    font-size: $fs_medium_small;
  }
  &_progress {
    font-size: $fs_xsmall;
    &[data-disabled="true"] {
      color: $c_cement-default;
    }
    &[data-type="italic"] {
      font-style: italic;
    }
  }
  &_title,
  &_progress {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &_count {
    color: $c_done;
  }
  &_menu_item {
    &:not(:last-child) {
      border-bottom: 1px solid $c_concrete-shade-3;
    }
  }
  &_menu {
    position: absolute;
    overflow-y: hidden;
    max-height: 80vh;
    width: auto;
    min-width: 230px;
    max-width: 300px;
    background-color: $c_white;
    box-shadow: 0px 10px 20px 0px #00000030;
    margin-top: 28px;
  }

  &_menu &_title {
    color: $c_grey-black;
  }

  &_link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $c_text;
    padding: 12px 12px 12px 0px;
    border-left: 6px solid rgba(0, 0, 0, 0);
    &.active {
      background-color: $c_grey-lighter;
      border-left: 6px solid $c_cyan-1;
      text-decoration: none;
    }
    &:hover {
      background-color: $c_grey-lighter;
      border-color: $c_menu_border--hover;
      text-decoration: none;
    }

    .icon-tick {
      width: 16px;
      color: $c_done;

      svg {
        width: 100%;
        height: auto;
      }
    }
    .icon-lock {
      width: 16px;
      color: $c_text--disabled;

      svg {
        width: 100%;
        height: auto;
      }
    }
  }
}
