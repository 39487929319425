@use 'src/styles/colors';
@use 'src/styles/variables';

.progress-bar {
  width: 100%;
  height: 6px;
  background-color: colors.$c_grey-black;
  position: relative;
  margin-top: variables.$spacing-xxxxxs;

  &[data-variant="secondary"] {
    background-color: colors.$c_concrete-shade-1;
  }

  &_percent {
    position: absolute;
    height: 6px;
    background-color: colors.$c_done;
  }
}
