.rich-text-editor {
  width: 100%;

  &--primary {
    @include textinput(
      $background-color-hover: $c_textfield_background--active,
      $background-color-focus: $c_textfield_background--active,
      $background-color-active: $c_textfield_background--active
    );
  }

  &--secondary {
    @include textinput;
  }

  &--tertiary {
    @include textinput-dark;
  }

  transition: background-color 0.1s;
}

.rich-text-editor_toolbar {
  background: $c_bg_light;
  display: flex;
  flex-wrap: wrap;
  padding: $spacing-xxxs;
}

.rich-text-editor_toolbar-button {
  width: 24px;
  height: 24px;
  background: transparent;
  border: none;
  color: $c_textfield_icon;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &--active {
    background: $c_button_background--active;
    color: $c_button_text--active;
  }

  &:hover,
  &:focus {
    background: $c_button_background;
    color: $c_concrete-shade-2;
  }

  svg {
    height: 16px;
    width: auto;
  }
}

.rich-text-editor_editor {
  a,
  .rich-text-editor_link-button {
    color: $c_accent-highlight;
    text-decoration: underline;
    border: 1px solid rgba(white, 0.3);
    padding: 0 0.2em;
    border-radius: 4px;
    background: transparent;
  }

  a,
  p,
  span,
  li {
    cursor: text;
  }

  & > h2,
  & > h3,
  & > p,
  & > ul,
  & > ol {
    margin-top: $spacing-xxs;
  }
}

.rich-text-editor_document-selector {
  padding-top: $spacing-xxxs;
}
