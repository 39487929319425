.guide-viewer-routine {
  &_wrapper {
    max-width: $w_page-content-width;
    width: 100%;
    margin: 4rem auto;

    &[data-editing="true"] {
      border-left-color: $c_not_started;

      &:after {
        content: "";
        position: fixed;
        inset: 0;
        background-color: black;
        opacity: 0.65;
        height: 100%;
        width: 100%;
        z-index: 1;
      }
    }
  }

  margin-bottom: 1rem;
  border-left: 12px solid $c_in_progress;

  &_title {
    @extend .h1;
  }

  [data-editing="true"] & {
    border-left-color: $c_not_started;
    position: relative;
    z-index: 2;
  }

  &_topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $c_blue-1;
    min-height: 42px;
  }
  &_subtitle {
    margin-left: $spacing-xs;
    font-size: $fs_xsmall;
    color: $c_cement-3;

    input {
      font-size: $fs_xsmall;
      color: $c_cement-3;
      font-family: $ff_body;
      background: none;
      border: none;

      &:hover {
        background-color: $c_concrete-default;
        border-radius: 5px;
      }
    }
    &_edit {
      width: 12px;
      display: inline-block;
      transform: translateY(-1px);
      margin-right: 0.5rem;
      margin-bottom: 0;
      svg {
        width: 100%;
        height: auto;
      }
    }
  }
  &_tools {
    display: flex;
  }

  &_content {
    background-color: $c_concrete-default;
    color: $c_cement-3;

    &_description {
      padding: $spacing-m 108px;
      font-weight: 500;
      font-size: $fs_medium;

      &[data-missingtext="true"] {
        font-style: italic;
      }
      h4 {
        font-size: $fs_xlarge;
      }

      h5 {
        font-size: $fs_medium_large;
      }
    }

    &_files {
      padding: 0 108px $spacing-m 108px;
      display: flex;
      flex-direction: column;
      .button-link {
        margin-bottom: 0.5rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &_download {
      @extend .link_to_document;
      display: flex;
    }
  }

  &_button {
    background-color: transparent;
    border: none;
    color: $c_cement-default;
    padding: 1em;

    .icon-plus,
    .icon-tick,
    .icon-pencil {
      width: 16px;
    }

    .icon-plus {
      transform: rotate(45deg);
    }

    svg {
      width: 100%;
      height: auto;
    }
  }

  &_edit {
    padding: $spacing-m 108px;

    textarea {
      width: 100%;
      color: $c_cement-2;
      font-size: $fs_medium;
      line-height: $lh_base;
      font-family: $ff_body;
      background: none;
      border: none;
      min-height: 250px;

      &:hover {
        background-color: $c_concrete-shade-1;
        border-radius: 5px;
      }
    }
  }
}
