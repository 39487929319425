// Direct colors for Byggföretagen theme - do not use directly
// Got a hard time naming a color? See: https://chir.ag/projects/name-that-color
$c_limed-spruce: #344147;
$c_grey-medium: #4e5c62;
$c_grey-light: #5f6d73;
$c_grey-blue: #adc0c9;
$c_grey-lighter: #D1DBE2;
$c_white: #fff;
$c_offwhite: #f6f8f9;

$c_cyan-0: #26A7FF;
$c_cyan-1: #5EBBFF;
$c_cyan-2: #D8ECFA;
$c_cyan-3: #EBF5FE;

$c_blue-0: #082E4B;
$c_blue-1: #1B4B6F;
$c_blue-2: #496F8C;
$c_blue-3: #7693A9;
$c_blue-4: #A4B7C5;
$c_blue-5: #D1DBE2;
$c_blue-6: #F2F5F7;
$c_blue-focus: #0F62FE;


$c_yellow: $c_cyan-1;
$c_lime: $c_yellow;
$c_crayfish: #FF634F;

$c_concrete-default: #496F8C;
$c_concrete-shade-1: #1B4B6F;
$c_concrete-shade-2: #082E4B;
$c_concrete-shade-3: #bfc4c7;
$c_grey-black: #082E4B;

$c_skog-ton-3: #c2c7b9;
$c_cement-default: #b0c0c8;
$c_cement-2: #d0d9de;
$c_cement-3: #dfe6e9;
$c_accent-action: $c_crayfish;
$c_accent-action--disabled: mix($c_accent-action, $c_concrete-shade-2, 30%);
$c_accent-signal: $c_lime;
$c_accent-highlight: $c_yellow;
$c_accent-highlight--disabled: mix($c_accent-highlight, $c_concrete-shade-2, 30%);

$c_background-site: $c_concrete-shade-2;
$c_text-body: $c_white;
$c_accent-green: $c_lime;
$c_text-anchor: $c_yellow;

// Applied colors
//Text
$c_text: $c_white;
$c_text--disabled: $c_cement-default;
$c_text-inverted: $c_concrete-shade-2;

//Links
$c_anchor-inverted: $c_crayfish;

//Menu
$c_menu_border: $c_grey-medium;
$c_menu_border--hover: $c_cement-default;

//Form
$c_form_text: $c_grey-lighter;
$c_form_input: $c_concrete-default;
$c_form_icon: $c_yellow;
$c_form_input_focus: $c_blue-0;

$c_textfield_text: $c_white;
$c_textfield_text--hover: mix($c_white, $c_concrete-default, 90%);
$c_textfield_text--focus: mix($c_white, $c_concrete-default, 90%);
$c_textfield_text--active: $c_white;
$c_textfield_text--disabled: $c_concrete-default;

$c_textfield_background: $c_concrete-default;
$c_textfield_background--hover: $c_concrete-default;
$c_textfield_background--focus: $c_concrete-default;
$c_textfield_background--active: $c_concrete-shade-1;
$c_textfield_background--disabled: mix($c_concrete-default, $c_concrete-shade-2, 15%);

$c_textfield_border: transparent;
$c_textfield_border--hover: transparent;
$c_textfield_border--focus: transparent;
$c_textfield_border--active: transparent;
$c_textfield_border--disabled: transparent;

$c_textfield_icon: $c_yellow;
$c_textfield_icon--hover: $c_yellow;
$c_textfield_icon--focus: $c_yellow;
$c_textfield_icon--active: $c_yellow;
$c_textfield_icon--disabled: $c_yellow;

// Textfield dark
$c_textfield-dark_text: $c_white;
$c_textfield-dark_text--hover: mix($c_white, $c_concrete-default, 90%);
$c_textfield-dark_text--focus: mix($c_white, $c_concrete-default, 90%);
$c_textfield-dark_text--active: $c_white;
$c_textfield-dark_text--disabled: $c_concrete-default;

$c_textfield-dark_background: $c_concrete-shade-1;
$c_textfield-dark_background--hover: $c_concrete-shade-1;
$c_textfield-dark_background--focus: darken($c_concrete-shade-1, 5%);
$c_textfield-dark_background--active: darken($c_concrete-shade-1, 5%);
$c_textfield-dark_background--disabled: mix($c_concrete-default, $c_concrete-shade-2, 15%);

$c_textfield-dark_border: transparent;
$c_textfield-dark_border--hover: transparent;
$c_textfield-dark_border--focus: transparent;
$c_textfield-dark_border--active: transparent;
$c_textfield-dark_border--disabled: transparent;

$c_textfield-dark_icon: $c_yellow;
$c_textfield-dark_icon--hover: $c_yellow;
$c_textfield-dark_icon--focus: $c_yellow;
$c_textfield-dark_icon--active: $c_yellow;
$c_textfield-dark_icon--disabled: $c_yellow;

//User component
$c_user_component_icon: $c_cement-default;
$c_user_component_icon--hover: $c_white;

//Table
$c_table_bg: $c_concrete-shade-2;
$c_table_bg--hover: $c_concrete-default;
$c_table_bg_header: $c_concrete-default;
$c_table_border: $c_concrete-default;
$c_table_text--disabled: $c_cement-default;

//Document...
$c_document_text: $c_white;
$c_document_bg: $c_concrete-shade-1;
$c_document_border: $c_concrete-shade-1;
$c_document_anchor: $c_yellow;
$c_document_literation: $c_white;
$c_document_download_link: $c_white;
$c_document_download_file_type: $c_cement-default;

//Backgrounds
$c_bg_light: $c_concrete-default;
$c_bg_dark: $c_concrete-shade-1;
$c_bg_darkest: $c_concrete-shade-2;
$c_bg_inverted: $c_offwhite;

//Left navigation
$c_left_nav_border: $c_concrete-default;

// NavList
$c_button-list_text: $c_white;
$c_button-list_text--hover: $c_white;
$c_button-list_text--focus: $c_white;
$c_button-list_text--active: $c_limed-spruce;
$c_button-list_text--disabled: $c_cement-default;

$c_button-list_background: $c_concrete-shade-2;
$c_button-list_background--hover: $c_grey-medium;
$c_button-list_background--focus: $c_grey-medium;
$c_button-list_background--active: $c_white;
$c_button-list_background--disabled: $c_grey-medium;

$c_button-list_border: $c_concrete-default;
$c_button-list_border--hover: $c_concrete-default;
$c_button-list_border--focus: $c_concrete-default;
$c_button-list_border--active: $c_white;
$c_button-list_border--disabled: $c_concrete-default;

$c_button-list_icon: $c_white;
$c_button-list_icon--hover: $c_white;
$c_button-list_icon--focus: $c_white;
$c_button-list_icon--active: $c_limed-spruce;
$c_button-list_icon--disabled: $c_cement-default;

//Status colors
$c_not_started: $c_cement-default;
$c_in_progress: $c_accent-highlight;
$c_done: $c_cyan-1;
$c_error: $c_crayfish;
$c_error_background: $c_grey-black;

//Button colors
$c_button_text: $c_concrete-shade-2;
$c_button_text--hover: $c_concrete-default;
$c_button_text--focus: $c_concrete-default;
$c_button_text--active: $c_concrete-default;
$c_button_text--disabled: mix($c_concrete-shade-2, $c_accent-highlight, 80%);

$c_button_background: $c_accent-highlight;
$c_button_background--hover: $c_accent-highlight;
$c_button_background--focus: $c_accent-highlight;
$c_button_background--active: $c_accent-highlight;
$c_button_background--disabled: $c_accent-highlight--disabled;

$c_button_border: $c_accent-highlight;
$c_button_border--hover: $c_accent-highlight;
$c_button_border--focus: $c_accent-highlight;
$c_button_border--active: $c_accent-highlight;
$c_button_border--disabled: $c_accent-highlight--disabled;

$c_button_outline: transparent;

//Button inverted colors
$c_button-inverted_text: $c_accent-highlight;
$c_button-inverted_text--hover: $c_accent-highlight;
$c_button-inverted_text--focus: $c_accent-highlight;
$c_button-inverted_text--active: $c_accent-highlight;
$c_button-inverted_text--disabled: rgba($c_accent-highlight, 0.6);

$c_button-inverted_background: $c_concrete-shade-1;
$c_button-inverted_background--hover: $c_concrete-default;
$c_button-inverted_background--focus: $c_concrete-default;
$c_button-inverted_background--active: $c_concrete-default;
$c_button-inverted_background--disabled: rgba($c_concrete-shade-2, 0.3);

$c_button-inverted_border: $c_white;
$c_button-inverted_border--hover: $c_white;
$c_button-inverted_border--focus: $c_white;
$c_button-inverted_border--active: $c_white;
$c_button-inverted_border--disabled: $c_concrete-shade-1;

$c_button-inverted_outline: transparent;

//Button secondary colors
$c_button-secondary_text: $c_white;
$c_button-secondary_text--hover: $c_cement-default;
$c_button-secondary_text--focus: $c_cement-default;
$c_button-secondary_text--active: $c_cement-default;
$c_button-secondary_text--disabled: rgba($c_white, 0.3);

$c_button-secondary_background: $c_concrete-shade-2;
$c_button-secondary_background--hover: $c_concrete-shade-2;
$c_button-secondary_background--focus: $c_concrete-shade-2;
$c_button-secondary_background--active: $c_concrete-shade-2;
$c_button-secondary_background--disabled: $c_concrete-shade-2;

$c_button-secondary_border: $c_white;
$c_button-secondary_border--hover: $c_cement-default;
$c_button-secondary_border--focus: $c_cement-default;
$c_button-secondary_border--active: $c_cement-default;
$c_button-secondary_border--disabled: rgba($c_white, 0.3);

$c_button-secondary_outline: transparent;

//Button secondary inverted colors
$c_button-secondary-inverted_text: $c_concrete-shade-2;
$c_button-secondary-inverted_text--hover: $c_concrete-default;
$c_button-secondary-inverted_text--focus: $c_concrete-default;
$c_button-secondary-inverted_text--active: $c_concrete-default;
$c_button-secondary-inverted_text--disabled: rgba($c_concrete-shade-2, 0.3);

$c_button-secondary-inverted_background: $c_offwhite;
$c_button-secondary-inverted_background--hover: $c_offwhite;
$c_button-secondary-inverted_background--focus: $c_offwhite;
$c_button-secondary-inverted_background--active: $c_offwhite;
$c_button-secondary-inverted_background--disabled: $c_offwhite;

$c_button-secondary-inverted_border: $c_concrete-shade-2;
$c_button-secondary-inverted_border--hover: $c_concrete-default;
$c_button-secondary-inverted_border--focus: $c_concrete-default;
$c_button-secondary-inverted_border--active: $c_concrete-default;
$c_button-secondary-inverted_border--disabled: rgba($c_concrete-shade-2, 0.3);

$c_button-secondary-inverted_outline: transparent;

//Button guide tool
$c_button-guide-tool_text: $c_white;
$c_button-guide-tool_text--hover: $c_white;
$c_button-guide-tool_text--focus: $c_white;
$c_button-guide-tool_text--active: $c_white;
$c_button-guide-tool_text--disabled: rgba($c_white, 0.6);

$c_button-guide-tool_icon: $c_cement-default;
$c_button-guide-tool_icon--hover: $c_yellow;
$c_button-guide-tool_icon--focus: $c_yellow;
$c_button-guide-tool_icon--active: $c_yellow;
$c_button-guide-tool_icon--disabled: rgba($c_cement-default, 0.6);

$c_button-guide-tool_background: $c_grey-black;
$c_button-guide-tool_background--hover: $c_grey-black;
$c_button-guide-tool_background--focus: $c_grey-black;
$c_button-guide-tool_background--active: $c_grey-black;
$c_button-guide-tool_background--disabled: rgba($c_grey-black, 0.3);

$c_button-guide-tool_border: $c_grey-black;
$c_button-guide-tool_border--hover: $c_grey-black;
$c_button-guide-tool_border--focus: $c_grey-black;
$c_button-guide-tool_border--active: $c_grey-black;
$c_button-guide-tool_border--disabled: rgba($c_grey-black, 0);

$c_button-guide-tool_outline: transparent;

// Button warning
$c_button-warning_text: $c_white;
$c_button-warning_text--hover: $c_concrete-default;
$c_button-warning_text--focus: $c_concrete-default;
$c_button-warning_text--active: $c_concrete-default;
$c_button-warning_text--disabled: rgba($c_concrete-shade-2, 0.8);

$c_button-warning_background: $c_accent-action;
$c_button-warning_background--hover: $c_accent-action;
$c_button-warning_background--focus: $c_accent-action;
$c_button-warning_background--active: $c_yellow;
$c_button-warning_background--disabled: rgba($c_accent-action, 0.3);

$c_button-warning_border: $c_accent-action;
$c_button-warning_border--hover: $c_accent-action;
$c_button-warning_border--focus: $c_accent-action;
$c_button-warning_border--active: $c_yellow;
$c_button-warning_border--disabled: rgba($c_accent-action, 0.3);

// Button square icon
$c_button-select_text: $c_cement-3;
$c_button-select_text--hover: mix($c_cement-3, $c_concrete-default, 90%);
$c_button-select_text--focus: mix($c_cement-3, $c_concrete-default, 90%);
$c_button-select_text--active: $c_white;
$c_button-select_text--disabled: $c_concrete-default;

$c_button-select_background: $c_concrete-default;
$c_button-select_background--hover: $c_concrete-default;
$c_button-select_background--focus: $c_concrete-default;
$c_button-select_background--active: $c_concrete-default;
$c_button-select_background--disabled: mix($c_concrete-default, $c_concrete-shade-2, 15%);

$c_button-select_border: transparent;
$c_button-select_border--hover: transparent;
$c_button-select_border--focus: transparent;
$c_button-select_border--active: transparent;
$c_button-select_border--disabled: transparent;

$c_button-select_icon: $c_accent-highlight;
$c_button-select_icon--hover: mix($c_accent-highlight, $c_concrete-default, 90%);
$c_button-select_icon--focus: mix($c_accent-highlight, $c_concrete-default, 90%);
$c_button-select_icon--active: $c_white;
$c_button-select_icon--disabled: $c_concrete-default;

// Button square icon
$c_button-squareicon_text: $c_concrete-shade-2;
$c_button-squareicon_text--hover: $c_concrete-default;
$c_button-squareicon_text--focus: $c_concrete-default;
$c_button-squareicon_text--active: $c_concrete-default;
$c_button-squareicon_text--disabled: rgba($c_concrete-shade-2, 0.8);

$c_button-squareicon_background: $c_accent-highlight;
$c_button-squareicon_background--hover: $c_accent-highlight;
$c_button-squareicon_background--focus: $c_accent-highlight;
$c_button-squareicon_background--active: $c_yellow;
$c_button-squareicon_background--disabled: $c_accent-highlight--disabled;

$c_button-squareicon_border: transparent;
$c_button-squareicon_border--hover: transparent;
$c_button-squareicon_border--focus: transparent;
$c_button-squareicon_border--active: transparent;
$c_button-squareicon_border--disabled: transparent;

$c_button-squareicon_icon: $c_white;
$c_button-squareicon_icon--hover: $c_accent-highlight;
$c_button-squareicon_icon--focus: $c_accent-highlight;
$c_button-squareicon_icon--active: $c_white;
$c_button-squareicon_icon--disabled: $c_accent-highlight--disabled;

$c_button-squareicon_icon-background: $c_grey-black;
$c_button-squareicon_icon-background--hover: $c_grey-black;
$c_button-squareicon_icon-background--focus: $c_grey-black;
$c_button-squareicon_icon-background--active: $c_grey-black;
$c_button-squareicon_icon-background--disabled: mix($c_grey-black, $c_concrete-shade-2, 30%);

// Button iconbutton
$c_button-iconbutton_text: $c_white;
$c_button-iconbutton_text--hover: $c_white;
$c_button-iconbutton_text--focus: $c_white;
$c_button-iconbutton_text--active: $c_white;
$c_button-iconbutton_text--active-hover: $c_accent-highlight;
$c_button-iconbutton_text--disabled: rgba($c_white, 0.3);

$c_button-iconbutton_background: transparent;
$c_button-iconbutton_background--hover: transparent;
$c_button-iconbutton_background--focus: transparent;
$c_button-iconbutton_background--active: transparent;
$c_button-iconbutton_background--disabled: transparent;

$c_button-iconbutton_border: transparent;
$c_button-iconbutton_border--hover: transparent;
$c_button-iconbutton_border--focus: transparent;
$c_button-iconbutton_border--active: transparent;
$c_button-iconbutton_border--disabled: transparent;

$c_button-iconbutton_icon: $c_white;
$c_button-iconbutton_icon--hover: $c_accent-highlight;
$c_button-iconbutton_icon--focus: $c_accent-highlight;
$c_button-iconbutton_icon--active: $c_accent-highlight;
$c_button-iconbutton_icon--disabled: $c_accent-highlight--disabled;

$c_button-iconbutton_icon-background: $c_grey-black;
$c_button-iconbutton_icon-background--hover: $c_grey-black;
$c_button-iconbutton_icon-background--focus: $c_grey-black;
$c_button-iconbutton_icon-background--active: $c_grey-black;
$c_button-iconbutton_icon-background--disabled: mix($c_grey-black, $c_concrete-shade-2, 30%);

// Toggle bar
// NB! For THIS project the color doesn't have a state change.
$c_togglebar_text: $c_white;
$c_togglebar_text--hover: $c_white;
$c_togglebar_text--focus: $c_white;
$c_togglebar_text--active: $c_white;
$c_togglebar_text--disabled: $c_white;

$c_togglebar_background: $c_concrete-default;
$c_togglebar_background--hover: $c_concrete-default;
$c_togglebar_background--focus: $c_concrete-default;
$c_togglebar_background--active: $c_concrete-default;
$c_togglebar_background--disabled: $c_concrete-default;

$c_togglebar_border: transparent;
$c_togglebar_border--hover: transparent;
$c_togglebar_border--focus: transparent;
$c_togglebar_border--active: transparent;
$c_togglebar_border--disabled: transparent;

$c_togglebar_icon-color: $c_yellow;
$c_togglebar_icon-color--hover: $c_yellow;
$c_togglebar_icon-color--focus: $c_yellow;
$c_togglebar_icon-color--active: $c_yellow;
$c_togglebar_icon-color--disabled: $c_yellow;
//
$c_noticebutton_text: $c_white;
$c_noticebutton_text--hover: $c_white;
$c_noticebutton_text--focus: $c_white;
$c_noticebutton_text--active: $c_white;
$c_noticebutton_text--disabled: $c_white;

$c_noticebutton_background: $c_limed-spruce;
$c_noticebutton_background--hover: $c_limed-spruce;
$c_noticebutton_background--focus: $c_limed-spruce;
$c_noticebutton_background--active: $c_limed-spruce;
$c_noticebutton_background--disabled: rgba($c_limed-spruce, 0.3);

$c_noticebutton_border: transparent;
$c_noticebutton_border--hover: transparent;
$c_noticebutton_border--focus: transparent;
$c_noticebutton_border--active: transparent;
$c_noticebutton_border--disabled: transparent;

$c_noticebutton_icon: $c_grey-blue;
$c_noticebutton_icon--hover: $c_accent-highlight;
$c_noticebutton_icon--focus: $c_accent-highlight;
$c_noticebutton_icon--active: $c_accent-highlight;
$c_noticebutton_icon--disabled: $c_grey-blue;

//Radiobutton & checkbox
$c_choice_text: $c_offwhite;
$c_choice_text--hover: rgba($c_cement-default, 0.6);
$c_choice_text--focus: $c_offwhite;
$c_choice_text--active: $c_offwhite;
$c_choice_text--disabled: rgba($c_cement-default, 0.6);
$c_choice_border: $c_blue-5;
$c_choice_border--hover: $c_blue-5;
$c_choice_border--focus: $c_blue-focus;
$c_choice_border--active: $c_blue-3;
$c_choice_border--disabled: $c_blue-2;

$c_choice_background: $c_grey-black;
$c_choice_background--checked: $c_white;
$c_choice_background--disabled: rgba($c_cement-default, 0.6);

:root {
  --c_background-site: #{$c_background-site};
  --c_text-body: #{$c_text-body};
  --c_accent-highlight: #{$c_accent-highlight};
  --c_accent-green: #{$c_accent-green};
  --c_text-anchor: #{$c_text-anchor};

  --c_searchable-text-color: var(--c_text-body);
  --c_searchable_backgrund-color: #{$c_form_input};
  --c_searchable_backgrund-color--focus: #{$c_form_input_focus};
  --c_searchable_icon-color: #{$c_yellow};
  --c_searchable_icon-color--hover: rgba(255, 252, 77, 0.5);
  --c_searchable_background-color-dark: #{$c_limed-spruce};
}
