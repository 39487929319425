.calendar-activity {
  &_header {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    background-color: $c_bg_dark;
    padding: $spacing-s;
  }

  &_heading {
    margin-bottom: 0;
    align-self: center;
  }

  &_sub-heading {
    color: $c_text--disabled;
    font-size: $fs_xsmall;
  }

  &_content {
    display: flex;
    flex-direction: column;
    padding: $spacing-s;
    background-color: $c_bg_light;
  }

  &_text {
    > *:first-child {
      margin-top: 0;
    }

    a {
      @extend .link_to_document;
    }
  }
}
