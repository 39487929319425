.supervisors {
  padding-top: 1rem;
  padding-bottom: 100px;

  &_list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 30px;
    &_item {
      @extend .table;
      &.table-header {
        padding: 12px 24px 10px;
      }

      padding: 16px 24px;
      grid-template-columns: 2fr 3fr 1fr 0.8fr 0.8fr;

      .iconHolder {
        text-align: center;
      }

      .icon {
        margin-right: 5px;
        margin-bottom: 4px;
      }

      .not-set {
        font-style: italic;
      }
    }
  }

  &_actions {
    display: flex;
    margin-top: 10px;
  }

  &_button {
    @extend .button-inverted;
    flex: 1;
  }

  &_add {
    @extend .button;
    margin-bottom: $gutter;
  }

  &_link {
    background: none;
    border: none;
    display: flex;
    padding: 0;
    line-height: 1.5;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &_instances {
    margin-bottom: $spacing-xs;
    max-height: 50vh;
    overflow: auto;

    &_instance {
      font-size: $fs_medium_small;
      margin-bottom: $spacing-xxxs;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }
  .form_overlay_button-delete {
    justify-content: center;
  }
}
