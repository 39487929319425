.news {
  padding-top: 1rem;

  &_title {
  }

  &_list {
    list-style: none;
    margin: 0;
    padding: 0;
    &_item {
      @extend .table;
      grid-template-columns: 0.5fr 1fr 3fr 2fr 1fr;

      .icon {
        margin-right: 5px;
        margin-bottom: 4px;
      }

      .not-set {
        font-style: italic;
      }
    }
  }
  .rich-text-editor {
    padding-left: 0;
    padding-right: 0;
    .rich-text-editor_editor {
      padding: 0 9px;
    }
  }

  &_add {
    @extend .button;
    margin-bottom: $gutter;
  }
}
