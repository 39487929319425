.link_to_document {
  color: $c_document_anchor;
  background: none;
  border: none;
  font-size: $fs_medium_small;
  padding: 0;
  font-family: $ff_body;
  cursor: pointer;
  margin-top: 0.5rem;
  &:hover {
    text-decoration: underline;
  }

  svg {
    margin-right: $spacing-xxxxs;
  }
}
