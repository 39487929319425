//TODO: some of the units are not correct, due to the fact there is no base font-size set.
.my-todos {
  &_item {
    background-color: $c_grey-medium;
  }

  &_container {
    padding: $spacing-xs $spacing-s;
    display: flex;
    flex-direction: column;
    gap: $spacing-s;
  }

  &_button {
    @include button-factory($icon-position: "right", $size: "MS", $padding: "0.75em 1em");
    width: 100%;

    svg {
      height: 1em;
    }
  }

  &_meta {
    margin-bottom: $spacing-xxs;
  }

  &_deadline {
    &[data-variant="passed"] {
      color: $c_accent-action;
    }

    &[data-variant="soon"] {
      color: $c_accent-highlight;
    }

    &[data-variant="far"] {
      color: $c_accent-signal;
    }
  }
}
