@use 'src/styles/colors';
@use 'src/styles/variables';

.calendar {
  &_actions {
    margin: $spacing-l 0 $spacing-m;
    display: flex;
    gap: $spacing-m;
  }

  &_action {
    color: $c_document_anchor;
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    padding: none;
    font-family: $ff_body;
    font-size: $fs_medium_small;
    cursor: pointer;

    svg {
      margin-right: $spacing-xxxs;
    }

    &:focus-visible {
      outline: solid colors.$c_text-anchor 2px;
    }
  }
}
