.tools {
  display: flex;
  justify-content: center;
  &_intro {
    @extend .hero-description;
  }
  &_list {
    max-width: 950px;
    padding: 60px 0;
    list-style: none;
    margin: 0;
    &_item {
      margin-bottom: 12px;

      &_main {
        display: flex;
        padding: 60px;
        color: $c_white;
        background-color: $c_concrete-shade-1;
        margin-bottom: 2px;
        &_content {
          table {
            .kol2 {
              padding-left: 3rem;
              text-align: right;
            }
          }
        }
      }
      &_footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 60px;
        background-color: $c_concrete-shade-1;
        font-size: 24px;
        line-height: 36px;
        font-weight: 700;
      }
    }
  }
}
