.assign {
  &_description {
    margin-bottom: $spacing-l;
  }
  &_row {
    @extend .form_overlay_form-row;
    margin-bottom: 1rem;
  }
  &_button {
    display: flex;
    justify-content: flex-end;
  }
}
