//TODO: some of the units are not correct, due to the fact there is no base font-size set.
.my-news {
  &_item {
    background-color: $c_grey-medium;
  }

  &_header {
    display: flex;
    justify-content: space-between;
    padding: $spacing-xs $spacing-s;
  }

  &_region {
    padding: 0 $spacing-s $spacing-s $spacing-s;

    &[hidden] {
      display: none;
    }
  }
}
