.new-users {
    &_header {
        background-color: $c_bg_light;
        padding: $spacing-s;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: $spacing-xs;
    }
  
    &_title {
        margin: 0;
    }
  
    &_content {
        background: $c_grey-medium;
        padding: $spacing-s;
        & > p {
            margin-top: 0;
        }
    }
  }
  