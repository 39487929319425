.status-message {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: $spacing-m $spacing-m $spacing-m $spacing-xs;
  z-index: 101;
  transition: visibility 0s ease 0.25s, opacity 0.25s ease;
  background-color: $c_grey-black;
  display: flex;
  align-items: center;
  &_icon {
    margin-right: $spacing-xs;
    background: transparent;
    border: none;
    [data-status="ERROR"] & {
      color: $c_accent-action;
    }
  }
  &_action_link {
    background: transparent;
    border: none;
    color: inherit;
    padding: 0;
    cursor: pointer;
  }
  &--active {
    opacity: 1;
    visibility: visible;
    transition: visibility 0s ease 0s, opacity 0.25s ease;
  }

  &[data-status="ERROR"] {
    border-left: solid 12px $c_accent-action;
  }

  h2 {
    margin-bottom: 0;
  }
}
