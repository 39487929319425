@use "sass:math";
.comment {
  &_form {
    display: flex;
    align-items: stretch;
    width: 100%;
    position: relative;
    padding-bottom: $spacing-m;
    &:after {
      content: "";
      border-bottom: solid 1px $c_concrete-default;
      position: absolute;
      left: -$spacing-m;
      bottom: 0;
      width: calc(100% + #{$spacing-m * 2});
    }
  }
  &_input {
    background: none;
    border: none;
    color: $c_text;
    flex-grow: 1;
  }
  &_submit {
    @extend .button;
    padding: $spacing-xxs $spacing-xxs $spacing-xxxs $spacing-xxs;
    border-width: 0;
    flex-grow: 0;

    &_icon {
      width: 16px;

      svg {
        fill: none;
        width: 100%;
        height: auto;
      }
    }
    &:hover,
    &:active,
    &[data-disabled="true"] {
      svg {
        fill: none;
      }
    }
  }
  &_no-comments {
    padding-top: $spacing-m;
  }
  &_row {
    padding-top: math.div($spacing-m, 2);
    padding-bottom: math.div($spacing-m, 2);
    position: relative;
    &:after {
      content: "";
      border-bottom: solid 1px $c_concrete-default;
      position: absolute;
      left: -$spacing-m;
      bottom: 0;
      width: calc(100% + #{$spacing-m * 2});
    }
    &:last-child {
      padding-bottom: 0;
      &:after {
        display: none;
      }
    }

    &_label {
      font-size: $fs_xsmall;
      .date {
        color: $c_cement-3;
      }
    }
    &_comment {
      font-size: $fs_medium_small;
      font-weight: $fw_medium;
    }
  }
}
