@mixin doc-editor-layout {
  display: flex;
  flex-wrap: wrap;
  aside {
    flex-basis: 100%;
    min-width: 100px;
    @media screen and (min-width: 768px) {
      flex-basis: 30%;
    }
    @media screen and (min-width: 1024px) {
    }
    @media screen and (min-width: #{$max-width}px) {
      flex-basis: 25%;
    }
  }
  article {
    flex-basis: 100%;
    padding-left: 1rem;
    @media screen and (min-width: 768px) {
      // flex-basis: 60%;
      flex-basis: 70%;
    }
    @media screen and (min-width: 1024px) {
      padding-left: 2rem;
    }
    @media screen and (min-width: #{$max-width}px) {
      flex-basis: 75%;
      padding-left: 3rem;
      max-width: 75%;
    }
  }
}
