@use "src/styles/variables";

.typography-heading-meta {
  display: flex;
  flex-direction: column;

  &_heading {
    font-size: variables.$fs_base;
    font-weight: variables.$fw_normal;
    margin-bottom: 0;
  }
}
