@use "src/styles/variables";

.layout-columns {
  display: grid;
  grid-gap: var(--layout-columns--column-gap, #{variables.$spacing-s})
    var(--layout-columns--row-gap, #{variables.$spacing-s});
  grid-template-columns: repeat(
    auto-fit,
    minmax(var(--layout-columns--min-width, 0), var(--layout-columns--max-width, 1fr))
  );
  width: 100%;
}
