.sub-footer {
  background-color: $c_bg_dark;
  padding: $spacing-l 0;

  &-container {
    @include container;
  }

  &_columns {
    display: grid;
    grid-template-columns: 1fr;

    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: $spacing-xxs;
    }
  }

  &_column {
    margin-top: $spacing-l;

    @media screen and (min-width: 768px) {
      margin-top: 0;
    }

    &[data-col="middle"] {
      a {
        margin-top: $spacing-xxs;
      }
    }
  }

  &_hours {
    margin-bottom: $spacing-xxs;
  }

  &_anchor {
    color: $c_document_anchor;
    display: flex;
    align-items: center;

    &[data-type="large"] {
      font-size: $fs_medium;
      line-height: 1;
    }
    svg {
      margin-right: $spacing-xxxs;
    }
  }
}
