.start-guide-button {
  &_wrapper {
    text-align: center;
    padding: $spacing-s;
    height: fit-content;
  }

  &_dark_wrapper {
    background-color: $c_grey-black;
    padding: $spacing-s;
    height: fit-content;
  }

  &_header {
    display: flex;
    justify-content: space-between;
  }

  &_heading {
    font-size: $fs_medium;
    font-weight: $fw_bold;
  }

  &_sub_header {
    margin: 0;
  }

  &_green {
    color: $c_lime;
  }

  &_button {
    margin-top: $spacing-s;
    @include button-factory($icon-position: "right", $size: "MS", $padding: "0.75em 1em");
    width: 100%;

    svg {
      height: 1em;
    }
  }

  &_button_row {
    display: flex;
    gap: 1.53em;
    margin-top: 1em;
    > * {
      flex: 1;
    }
  }

  &_company-name {
    margin-bottom: 3.06em;
  }

  &_choices-description {
    font-size: 0.8em;
  }

  &_document-choices {
    @include choice-group();
    flex-direction: column;
    &_label {
      margin-bottom: 2.06em;
    }
  }
}
