.guide-viewer-response-slots {
  max-width: 46.81em;
  margin: 0 4.25em 0 6.93em;
  padding-bottom: 1em;

  &_title,
  .h3 {
    font-weight: 900;
  }
  
  &_header {
    display: grid;
    grid-template-columns: .62fr .38fr;
    margin-bottom: 1em;
    color: $c_cement-3;
  }
  
  &_instances > :not(:last-child) {
    border-bottom: 2px solid $c_concrete-shade-3;
  }
}
