@mixin dropdown($background-color: $c_yellow, $icon-size: 1.5em) {
  position: relative;
  &:after {
    position: absolute;
    content: '';
    width: $icon-size;
    height: $icon-size;
    right: 1.5em;
    top: calc(50% - 8px);
    mask-image: url('/icons/chevron-down1.svg');
    mask-size: cover;
    -webkit-mask-image: url('/icons/chevron-down1.svg');
    -webkit-mask-size: cover;
    background-color: $background-color;
  }

  &_label {
    display: flex;
    margin-bottom: 0;
    color: inherit;
    select {
      padding: 1.1em 2em 1.1em 1em;
      font-family: $ff_body;
      font-size: 1rem;
      font-weight: 500;
      border-style: none;
      background-color: $c_form_input;
      color: $c_form_text;
    }

    &[data-labelPos='left'] {
      gap: 2em;
      align-items: center;
    }
    &[data-labelPos='top'] {
      flex-direction: column;
      gap: 0.5em;
    }
  }
}
