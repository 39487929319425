label#file,
label#title {
  &::before {
    content: "*";
    color: $c_accent-highlight;
    position: absolute;
    left: 8.5em;
    font-size: 2em;
    line-height: 1.3em;
  }

  input[data-invalid="true"] {
    border-bottom: 8px solid $c_error;
  }
}

.upload_success_msg {
  font-size: 1.5em;
  text-align: center;
  margin: 1em;
}
