.my-favourites-item {
  display: flex;

  &_header_wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: $spacing-l;
  }

  &_button {
    color: $c_white;
    font-size: $fs_medium_small;
    font-weight: $fw_normal;
    background-color: transparent;
    border: none;
  }

  &_wrapper {
    background-color: $c_concrete-shade-1;
    padding: $spacing-xs $spacing-s;
    width: 100%;
  }

  &_header_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;

    &_left {
      display: flex;
      align-items: center;
      gap: $spacing-xxxs;
      color: $c_yellow;
    }
    &_icon {
      display: flex;
      width: 16px;
    }
  }

  &_header {
    font-size: $fs_medium;
    font-weight: $fw_normal;
    margin: 0;
  }

  &_text {
    &[data-is-expanded="true"] {
      height: auto;
      &::after {
        content: none;
      }
    }

    color: $c_white;
    font-size: $fs_medium_small;
    font-weight: $fw_normal;
    margin-bottom: $spacing-xxxs;
    height: calc(1em * 4);
    overflow: hidden;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1em;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, $c_grey-medium 100%);
      bottom: 0;
      left: 0;
      pointer-events: none;
    }
  }

  &_overflow {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &_source {
    color: $c_skog-ton-3;
    font-size: $fs_xsmall;
    font-weight: $fw_normal;
    margin: 0;
  }

  &_wrapper_buttons {
    display: flex;
    justify-content: space-between;
    margin-top: $spacing-xs;
    margin-bottom: $spacing-xxs;
  }
}
