//@import "src/components/ChoiceGroup/ChoiceGroup";
@import "src/styles/elements/input";

.GuideEditor {
  &_page {
    @include doc-editor-layout;
    flex-wrap: wrap;
  }
  &_section-label {
    @include section-label;
  }
  .inputfield--text,
  &_textfield {
    @include textinput;
    width: 100%;
  }
  .inputfield--textarea,
  &_textarea {
    @include textinput;
    width: 100%;
    min-height: 7em;
  }
  &_fieldset {
    border: none;
    padding: 1em 0 1em 0;
    margin-bottom: 2rem;
  }
  &_section {
    .inputfield--text,
    &_textfield {
      @include textinput-dark;
      width: 100%;
    }
    .inputfield--textarea,
    &_textarea {
      @include textinput-dark;
      width: 100%;
      min-height: 7em;
    }
  }

  &_block[data-depth="2"] &_section-form {
    margin-bottom: 1em;
  }

  &_block[data-depth="3"] &_section-form {
    margin-bottom: 0;
  }

  &_block[data-depth="4"] &_section-form {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    gap: 1em;
    margin-bottom: 0;
    padding: 0;
    max-width: 43em;
  }

  &_block[data-depth="4"] &_block_header-bar {
    padding: 1.31em;
    &_buttons {
      background-color: $c_blue-1;
      &_inline {
        display: flex;
        align-items: flex-start;
        max-height: 2.1em;
        margin: 2.2em 0 0.875em 0;
      }
    }
  }

  &_block[data-depth="4"] &_footer {
    margin-top: 2em;
    padding: 0;
  }

  &_slot_dropdown {
    @include dropdown;
    &_label {
      min-width: 57%;
    }
    select {
      background-color: $c_blue-1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &_slot_checkbox {
    @include choice-group($font-size: 1em);
  }

  &_row {
    display: flex;
  }
  &_child-labels {
    &_container {
      padding: 2.13em 0 1.25em 1.5em;
    }

    &_label {
      font-size: 1em;
      font-weight: 500;
      line-height: 1.31em;
      margin: 0 0 0.88em 0;
    }
  }

  @include DocEditor;

  &_block-bar-input {
    background-color: $c_grey-medium;
    color: white;
    font-size: $fs_medium;
    font-weight: $fw_extrabold;
    border: 0;
    order: 0;
    margin-right: auto;
    margin-bottom: 0;
  }

  &_block {
    &[data-depth="2"] > &_wrapper {
      border-left: 1em solid $c_grey-lighter;
    }
    &[data-depth="4"] {
      margin-bottom: 0;
    }

    [open] {
      position: relative;
      z-index: 101;
    }

    [data-active="true"] &_background {
      position: fixed;
      background-color: black;
      opacity: 0.65;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: block;
      z-index: 100;
      cursor: default;
    }

    &_header-bar {
      background-color: $c_blue-2;

      &_title {
        font-size: 1.31em;
        line-height: 1.88em;
        color: $c_white;
        margin: 0;

        &:after {
          content: "";
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-left: 1em;

          // since we are using a svg file we need to do a workaround using mask
          -webkit-mask: url("../../../icons/Pencil.svg") no-repeat 50% 50%;
          mask-image: url("../../../icons/Pencil.svg") no-repeat 50% 50%;
          -webkit-mask-size: cover;
          mask-size: cover;
          background-color: $c_yellow;
        }
        &:hover {
          color: $c_yellow;
        }
      }

      &_link {
        flex: 1;
        font-size: 1em;
        min-width: 35em;

        &[data-type="title"] {
          order: 1;
        }
        &:hover {
          text-decoration: none;
        }
      }

      &_button {
        @include button-factory-iconbutton();
        padding: 0 0.5rem;

        &[data-type="delete"] {
          order: 2;
        }
        &[data-type="moveup"] {
          order: 3;
        }
        &[data-type="movedown"] {
          order: 4;
        }
      }

      &_description > * {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: start;

        font-size: $fs_medium_small;
        font-weight: $fw_medium;
        line-height: $fs_medium;
        color: white;

        padding: 1.2em 2em;
        max-width: 45em;
        margin: 0;
      }
    }
    [data-depth="4"] &_content {
      display: flex;
    }
  }
  &_footer {
    background-color: $c_blue-1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 1rem;
    padding: 0 2em 1.5em;
    &_text {
      font-style: italic;
      &[data-disabled="true"] {
        opacity: 0.3;
      }
    }
    &_button {
      @include button();
      min-width: 13em;
    }
  }
}
