.input-error-message {
  color: $c_accent-action;
  font-style: italic;
  position: absolute;
  margin: -1.333rem 0 0;
}
// General styles for text input
@mixin textinput(
  $color: $c_textfield_text,
  $color-hover: $c_textfield_text--hover,
  $color-focus: $c_textfield_text--focus,
  $color-active: $c_textfield_text--active,
  $color-disabled: $c_textfield_text--disabled,
  $background-color: $c_textfield_background,
  $background-color-hover: $c_textfield_background--hover,
  $background-color-focus: $c_textfield_background--focus,
  $background-color-active: $c_textfield_background--active,
  $background-color-disabled: $c_textfield_background--disabled,
  $border: "1px solid" $c_textfield_border,
  $border-color-hover: $c_textfield_border--hover,
  $border-color-focus: $c_textfield_border--focus,
  $border-color-active: $c_textfield_border--active,
  $border-color-disabled: $c_textfield_border--disabled,
  $border-radius: $border_radius,
  $padding: "0.666rem 1.333rem;",
  $font-size: $fs_medium_small,
  $font-weight: $fw_medium,
  $icon-position: "none"
) {
  & {
    color: $color;
    background-color: $background-color;
    border: #{$border};
    border-radius: $border-radius;
    padding: #{$padding};
    font-size: $font-size;
    font-weight: $font-weight;
    margin-bottom: 1.333rem;
  }

  &:hover,
  &:focus,
  &:focus-within {
    color: $color-hover;
    background-color: $background-color-hover;
    border-color: $border-color-hover;
  }
  &:focus {
    color: $color-focus;
    background-color: $background-color-focus;
    border-color: $border-color-focus;
  }

  &:active,
  &[data-active="true"] {
    color: $color-active;
    background-color: $background-color-active;
    border-color: $border-color-active;
  }
  &:disabled,
  &[data-disabled="true"] {
    color: $color-disabled;
    background-color: $background-color-disabled;
    border-color: $border-color-disabled;
  }
  &[data-haserror] {
    border-color: $c_accent-action;
  }
}

textarea {
  @include textinput();
  font-family: $ff_body;
}

@mixin textinput-dark(
  $color: $c_textfield-dark_text,
  $color-hover: $c_textfield-dark_text--hover,
  $color-focus: $c_textfield-dark_text--focus,
  $color-active: $c_textfield-dark_text--active,
  $color-disabled: $c_textfield-dark_text--disabled,
  $background-color: $c_textfield-dark_background,
  $background-color-hover: $c_textfield-dark_background--hover,
  $background-color-focus: $c_textfield-dark_background--focus,
  $background-color-active: $c_textfield-dark_background--active,
  $background-color-disabled: $c_textfield-dark_background--disabled,
  $border: "1px solid" $c_textfield-dark_border,
  $border-color-hover: $c_textfield-dark_border--hover,
  $border-color-focus: $c_textfield-dark_border--focus,
  $border-color-active: $c_textfield-dark_border--active,
  $border-color-disabled: $c_textfield-dark_border--disabled,
  $border-radius: $border_radius,
  $padding: "0.666rem 1.333rem;",
  $font-size: $fs_medium_small,
  $font-weight: $fw_medium
) {
  & {
    @include textinput(
      $color,
      $color-hover,
      $color-focus,
      $color-active,
      $color-disabled,
      $background-color,
      $background-color-hover,
      $background-color-focus,
      $background-color-active,
      $background-color-disabled,
      $border,
      $border-color-hover,
      $border-color-focus,
      $border-color-active,
      $border-color-disabled,
      $border-radius,
      $padding,
      $font-size,
      $font-weight
    );
  }
}
