.GuideWorkbookSelector {
  display: flex;
  flex-wrap: wrap;
  border: none;
  padding: 0;
  margin: 0;
  &_label {
    @include label;
  }
  &_selectors {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2rem;
    width: 100%;
  }
  .Selector {
    flex-grow: 1;
    flex-basis: 33.333%;
    max-width: 33.333%;
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
  &_submit {
    @include button-factory("S");
    margin-left: auto;
  }
}
