@mixin header($fontsize: $fs-base) {
  font-size: $fontsize;
  font-weight: $fw_bold;
  line-height: $lh_header;
  margin-bottom: 0.5em;
  margin-top: 0;
  // Don't be tempted to place a MARGIN-TOP here. It will only cause an unmanageble condition. -LR
  // Instead make sure the containing section/article/container has propper vertical padding set to it.
}
// Baserad på arbetsboken's löpande texten i skisserna. -LR
.hero {
  @include header($fs_3xlarge);
  font-weight: $fw_extrabold;
  letter-spacing: -0.86px;
}
h1,
.h1 {
  @include header($fs_2xlarge);
  font-weight: $fw_extrabold;
  letter-spacing: -0.66px;
}
h2,
.h2 {
  @include header($fs_medium_large);
}
h3,
.h3 {
  @include header($fs_medium);
}
h4,
.h4 {
  @include header($fs_base);
}
h5,
.h5 {
  @include header($fs_medium);
}
h6,
.h6 {
  @include header($fs_small);
}

.hero-description {
  width: 66%;
  font-size: $fs_large;
  line-height: 36px;
  color: $c_cement-3;
}

p {
  margin-bottom: 1em;
}

@mixin label($fontsize: $fs_xsmall) {
  font-size: $fontsize;
  font-weight: $fw_medium;
  line-height: $lh_base;
  margin-bottom: 0.5em;
}

label,
.label {
  @include label();
}

@mixin section-label($color: $c_cement-3) {
  display: block;
  font-size: $fs_xsmall;
  font-weight: $fw_medium;
  color: $color;
}

// Used for labels in section in the workbook and guide
.section-label {
  @include section-label;
}

.section-label-inverted {
  @include section-label($c_concrete-shade-1);
}

// TODO: add title declaration

// %title-L {
//   font-size: $fs_large;
//   font-weight: $fw_bold;
// }
//
// %title-M {
//   font-size: $fs_medium;
//   font-weight: $fw_black;
// }
//
// %text-L {
//   font-size: $fs_medium;
//   font-weight: normal;
// }
//
// %text-M {
//   font-size: $fs_base;
//   font-weight: $fw_bold;
// }
//
// %text-S {
//   font-size: $fs_small;
//   font-weight: normal;
// }
