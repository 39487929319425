.calendar-section {
  margin-bottom: $gutter;

  &--empty > &_header {
      background-color: $c_blue-1;
      color: $c_blue-4;
  }

  &--active {
    margin-bottom: $gutter_xlarge;
  }

  &_header {
    background-color: $c_blue-2;
    color: $c_white;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding-left: $spacing-m;
  }

  &_heading {
    margin-bottom: 0;
    align-self: center;
  }

  &_toggle {
    @extend .guide-viewer-requirement_button;

    &[disabled] {
      opacity: 0;
      pointer-events: none;
    }
  }

  &_region {
    padding-top: $gutter;
    display: flex;
    flex-wrap: wrap;
    gap: $gutter;

    &[hidden] {
      display: none;
    }

    > * {
      flex: calc(50% - #{$gutter} / 2);
      flex-grow: 0;
    }
  }
}
