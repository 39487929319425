@use "src/styles/colors";
@use "src/styles/variables";

.my-activities {
  [data-toggle="increment"] {
    transform: rotate(0.25turn);
  }

  [data-toggle="decrement"] {
    transform: rotate(-0.25turn);
  }

  &_item {
    background-color: colors.$c_grey-medium;
    padding: variables.$spacing-xs variables.$spacing-s;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;
    gap: variables.$spacing-xxs;
  }

  &_main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: variables.$spacing-xxs;
  }

  &_label {
    padding: variables.$spacing-xxxxs variables.$spacing-xxs;
    background: colors.$c_concrete-default;
    align-self: flex-start;
    font-size: variables.$fs_xsmall;
  }
}
