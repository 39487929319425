.GuideEditor_savebar {
  flex-basis: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2rem 0 3rem;

  &_button-back {
    // TODO: Change to icon combo
    // @extend .button-squareicon;
    @include button-factory-squareicon();
  }
  &_button-review {
    @extend .button;
  }
  &_button-save {
    @extend .button;
  }
  &_button-delete {
    @include button-factory-warning();
  }
}
