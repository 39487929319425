@mixin filter($ns: "filter") {
  .#{$ns} {
    display: flex;
    margin-bottom: 24px;
  }
  .#{$ns}_alternative {
    color: white;
    background: transparent;
    font-size: 16px;
    line-height: 1.44;
    padding: 5px;
    border: none;
    border-bottom: 5px solid transparent;
    margin-right: 5px;
    opacity: 0.65;
    cursor: pointer;
    // width: 100%;
    // height: 72px;

    &:focus {
      outline: none;
    }

    &[data-active="true"] {
      border-bottom-color: white;
      opacity: 1;
    }
  }
}

@include filter();
