@use "src/styles/colors";
@use "src/styles/variables";

.button-icon {
  --button-icon--icon-size: #{variables.$fs_medium_small};

  color: var(--button-icon--color, colors.$c_yellow);
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;

  &:focus-visible {
    outline:  solid colors.$c_text-anchor 2px;
  }

  svg {
    width: var(--button-icon--icon-size);
    height: var(--button-icon--icon-size);
  }

  &[data-size="medium"] {
    --button-icon--icon-size: #{variables.$fs_medium_small};
  }

  &[data-size="large"] {
    --button-icon--icon-size: #{variables.$fs_medium_large};
  }

  &[data-size="dynamic"] {
    --button-icon--icon-size: 1em;
  }
}
