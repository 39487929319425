.searchable-selector {
  .label {
    display: block;
  }

  &_option {
    cursor: pointer;
    padding-top: $spacing-xxxxs;
    padding-bottom: $spacing-xxxxs;
  }
  &_label {
    display: flex;
    flex: 1;
    font-size: $fs_base;
    line-height: 1;
  }
  &_sublabel {
    color: $c_accent-highlight;
    font-size: $fs_medium_small;
  }
}
