//@import "AddDocument";
//@import "DeleteDocument";

.companies {
  padding-top: 1rem;

  &_list {
    list-style: none;
    margin: 0;
    padding: 0;
    &_item {
      @extend .table;
      font-size: $fs_medium_small;
      grid-template-columns: 0.35fr 1fr 0.8fr 0.35fr 0.25fr 0.3fr 0.2fr 0.2fr;

      .icon {
        margin-right: 5px;
        margin-bottom: 4px;
      }
    }
  }

  &_actions {
    display: flex;
    margin-top: 10px;
  }

  &_link {
    background: none;
    border: none;
    display: flex;
    padding: 0;
    line-height: 1.5;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &_tools {
    display: flex;
    align-items: center;
    &[data-align="center"] {
      justify-content: center;
    }
    &[data-align="right"] {
      justify-content: flex-end;
    }
  }

  &_icon {
    width: 16px;

    svg {
      width: 100%;
      height: auto;
    }
  }

  &_guide_text {
    color: $c_choice_text;
    font-size: $fs_medium_small;

    &_small {
      display: flex;
      font-size: $fs_xsmall;
      line-height: $fs_base;  
      align-items: center;
    }
  }

  &_export {
    &-text {
      max-width: 600px;
    }
    .guide-export {
      font-size: $fs_base;
      .icon-download {
        width: 20px;
      }
    }
  }
}
