.users {
  padding-top: 1rem;

  &_list {
    margin: 0 0 2rem;
    padding: 0;
    color: $c_text;

    &_item {
      @extend .table;
      grid-template-columns: 2fr 3fr 2fr 1fr 0.5fr;
      grid-gap: $spacing-xxs;

      .icon {
        margin-right: 5px;
        margin-bottom: 4px;
      }

      .not-set {
        font-style: italic;
      }

      .link {
        text-decoration: underline;
      }
      &_nonvalidated {
        @extend .table;
        grid-template-columns: 2fr 3fr 2fr 1fr;
        grid-gap: $spacing-xxs;
      }
    }
  }

  &_edit {
    text-align: right;
  }

  &_actions {
    display: flex;
    margin-top: 10px;
  }

  &_button {
    @extend .button;
    flex: 1;
    padding: 0.5rem 1rem;

    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }

  &_link {
    background: none;
    border: none;
    display: flex;
    padding: 0;
    line-height: 1.5;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &_instances {
    margin-bottom: $spacing-xs;
    max-height: 50vh;
    overflow: auto;

    &_instance {
      font-size: $fs_medium_small;
      margin-bottom: $spacing-xxxs;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
    p {
      font-style: italic;
      margin-top: 0;
    }
  }
}
