.form_overlay {
  display: flex;
  flex-direction: column;
  min-width: 550px;
  gap: 2em;

  h2 {
    margin-bottom: 0;
  }

  &_form-row {
    display: flex;

    &_sub-header {
      font-size: $fs_base;
      font-weight: $fw_bold;
    }

    &[data-align="right"] {
      justify-content: flex-end;
    }

    .label {
      flex: 0 0 35%;
      font-size: $fs_base;
      margin-bottom: 0;
    }
    &.label-top {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .label {
        margin-bottom: 1em;
      }
    }
    .choice-group {
      flex: 0 0 35%;
      input {
        translate: 24px;
      }
    }
    > input,
    > textarea,
    > select {
      flex: 0 0 65%;
      padding: 5px 10px;
      min-height: 42px;
      font-family: $ff_body;
      border-style: none;
      background-color: $c_form_input;
      color: $c_form_text;
    }
    input[type="text"] {
      font-size: $fs_base;
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
    input[type="file"] {
      font-size: $fs_medium_small;
      line-height: 1.5;
    }
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding: 11px 10px;
      font-size: $fs_base;
      max-width: 65%;
    }
    /* workaround for the fact the after pseudo element is placed inside of select elements */
    &_select {
      :after {
        position: absolute;
        content: "";
        width: 1.5em;
        height: 1.5em;
        right: 27em;
        mask-image: url("/icons/chevron-down1.svg");
        mask-size: cover;
        -webkit-mask-image: url("/icons/chevron-down1.svg");
        -webkit-mask-size: cover;
        background-color: $c_yellow;
      }
    }

    .icon {
      color: $c_form_icon;
      position: relative;
      svg {
        position: absolute;
        left: -30px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    textarea {
      min-height: 150px;
    }

    .rich-text-editor {
      padding: 5px 10px;
      margin-bottom: 0;
    }

    .rich-text-editor_toolbar {
      padding: $spacing-xxxs 0 0;
    }
  }

  &_type-switch {
    @include choice-group();
    gap: 1em;
    width: auto;
  }

  &_delete-link {
    background: transparent;
    color: $c_accent-action;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      margin-right: $spacing-xxxxs;
    }
  }

  &_link {
    background: none;
    border: none;
    padding: 0;
    line-height: 1.5;
    color: white;
    margin-right: 10px;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &_button {
    @extend .button;
  }

  &_button-delete {
    color: $c_accent-action;
    display: inline-flex;
    gap: $spacing-xxxs;
    align-items: center;
  }

  &_image {
    display: block;
    width: 100%;
    max-height: 100px;
    margin-bottom: 5px;
  }

  &_actions {
    display: flex;
    gap: $spacing-xs;
  }
  &_dropdown {
    @include dropdown;
  }
}
