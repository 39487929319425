.upload-files {
  &_row {
    border-top: 1px solid $c_grey-medium;
    padding: $spacing-xxs 108px $spacing-m;

    font-size: $fs_xsmall; //12
    line-height: $fs_base; //18
    font-weight: 500;
    p {
      margin: 0;
    }
  }
  &_title {
    margin-bottom: $spacing-xxs;
  }
  &_when_who {
    color: $c_white;
  }
  &_when,
  &_who {
    color: #dfe6e9;
    display: inline-block;
    min-width: 65px;
    margin-right: 2.5em;
  }

  &_topbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
  }

  &_delete {
    color: $c_cement-default;
    background-color: transparent;
    border: none;
    cursor: pointer;
    &.upload {
      margin-right: 20px;
    }
  }

  &_download {
    @extend .link_to_document;

    display: inline-flex;
    align-items: center;
    gap: $spacing-xxs;
    align-items: flex-start;
    margin-top: 0;
  }

  &_button {
    &[data-first="true"] {
      @extend .button;
      flex-direction: row;
      svg {
        margin-left: 1rem;
      }
    }
    &[data-first="false"] {
      display: flex;
      flex-direction: row-reverse;
      background: transparent;
      border: none;
      color: $c_white;
      font-size: $fs_medium_small;
      cursor: pointer;
      svg {
        margin-left: 0;
        margin-right: $spacing-xxxxs;
        color: $c_cement-default;
      }
    }
  }

  &_actions {
    display: flex;
    gap: $spacing-s;
    color: $c_yellow;
    align-items: flex-start;
  }
}
