.guide-viewer-toolbar {
  position: relative;
  display: flex;
  //justify-content: space-between;   // TODO: pausad tills vidare ock ska tas bort när copyn för arbetsbökerna är klar
  justify-content: end;

  &_left {
  }

  &_right {
    display: flex;
  }

  &_button {
    @extend .button-guide-tool;
    .icon-tick,
    .icon-workbook {
      width: 24px;

      svg {
        width: 100%;
        height: auto;
      }
    }
    .icon-tick {
      transform: translateY(4px);
    }
  }
}
