.documents {
  &_link_back {
    @extend .link_to_document;
    margin-top: $spacing-m;
    margin-bottom: $spacing-m;
    display: block;
  }
  &_literation {
    color: $c_document_literation;
  }

  &_list-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $spacing-m;
  }

  &_type-switch {
    @include choice-group();
    gap: 1em;
  }
  &_list {
    list-style: none;
    margin: 0;
    padding: 0;
    &_item {
      border: 1px solid #fff;
      margin-bottom: 20px;

      &_header {
        display: flex;
        border-bottom: 2px solid $c_document_border;
        padding: $spacing-s $spacing-s $spacing-s $spacing-s * 1.5;
        align-items: flex-start;
        &_icon {
          width: 36px;
          margin-right: calc($spacing-m / 2);
        }
        &_content {
          flex: 2 auto;
        }
      }
      &_title {
        font-weight: 700;
        line-height: 36px;
        margin-bottom: 0;
      }
      &_last_date {
        margin: 0;
        font-size: $fs_xsmall;
        line-height: 18px;
        transform: translateY(-5px);
      }
      &_main {
        display: flex;
        justify-content: space-between;
        padding: $spacing-s $spacing-s $spacing-s $spacing-s * 1.5;
      }
      &_description {
        width: 50%;
        font-size: $fs_medium_small;
        line-height: 21px;
        display: flex;
        flex-direction: column;
        gap: $spacing-xxxs;
        .description {
          margin: 0;
        }
      }
    }
  }

  &_actions {
    display: flex;
    margin-top: 10px;
    gap: $spacing-s;
    color: $c_yellow;
    align-items: flex-start;
  }

  &_add {
    @extend .button;
  }

  &_copy-success {
    width: $fs_medium_small;
    height: $fs_medium_small;
    color: $c_accent-signal;
  }

  &_button_show-more {
    @extend .button;
    width: 100%;
    background-color: transparent;
    border-color: #fff;
    color: #fff;
  }
}

.form_overlay_version-link {
  font-size: $fs_small;
}
