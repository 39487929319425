@use "sass:math";

.notifications {
  &_button {
    display: flex;
    align-items: center;
    padding: 1em 1em 1em 0.5em;
    cursor: pointer;
    .icon-megaphone {
      width: 24px;
      position: relative;
      svg {
        width: 100%;
        height: auto;
      }
    }
    &[data-new="true"] {
      .icon-megaphone {
        &:after {
          position: absolute;
          content: "";
          background-color: $c_accent-action;
          border: solid 2px $c_concrete-shade-2;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          top: 0;
          right: 0;
        }
      }
    }
  }
  &_menu {
    position: absolute;
    background-color: $c_white;
    box-shadow: 0px 10px 20px 0px #00000030;
    top: calc(100% + 22px);
    width: 450px;
    transform: translateX(-338px);
    z-index: 1;
    &::before {
      border: inset 22px;
      content: "";
      display: block;
      height: 0px;
      width: 0px;
      border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $c_white rgba(0, 0, 0, 0);
      border-bottom-style: solid;
      position: absolute;
      top: -42px;
      right: 70px;
      z-index: 89;
    }

    &_top {
      padding: math.div($spacing-m, 2) $spacing-m;
    }
    &_content {
      overflow-y: scroll;
      max-height: 70vh;
      width: auto;
    }
    &_item {
      position: relative;
      padding: math.div($spacing-m, 2) $spacing-m;
      &:after {
        content: "";
        border-bottom: solid 1px $c_concrete-default;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      &[data-read="false"] {
        background-color: $c_concrete-shade-1;
        .notifications_menu_item_row_label {
          &::before {
            content: "";
            background-color: $c_accent-action;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: $spacing-xxxxs;
          }
        }
      }

      &_row {
        &:hover {
          text-decoration: none;
        }
        &_label {
          display: flex;
          align-items: center;
          font-size: $fs_xsmall;
          .date {
            color: $c_cement-3;
          }
        }
        &_text {
          font-size: $fs_medium_small;
          font-weight: $fw_medium;
          color: $c_white;

          .name {
            color: $c_yellow;
          }
        }
      }
    }
  }
}
