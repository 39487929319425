.supervisors-overview {
  &_list {
    padding: 0;
  }

  &_list_item {
    background-color: $c_grey-medium;
    width: 100%;
    margin-block: $spacing-s;
    display: flex;
    flex-wrap: wrap;

    &[data-highlight="true"] {
      border: 1px solid $c_yellow;
    }

    &_button_wrapper,
    &_top_wrapper {
      display: flex;
      flex-basis: 100%;
      justify-content: space-between;
    }

    &_top_wrapper {
      padding: $spacing-m;
    }

    &_wrapper {
      display: flex;
      flex-direction: row;
      flex-basis: 100%;
      max-height: 140px;
      overflow: hidden;

      &[data-is-expanded="true"] {
        max-height: initial;
      }
    }

    &_wrapper_image {
      margin-right: $spacing-m;
      background-color: $c_concrete-shade-2;
      flex-shrink: 0;
      width: 136px;
      height: 136px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
      }
    }

    &_wrapper_description {
      margin-left: $spacing-xxs;
    }

    &_name {
      font-size: $fs_medium_large;
      font-weight: $fw_bold;
      color: $c_white;
      margin-bottom: $spacing-xxxs;
      margin-top: 0;
    }

    &_company {
      font-size: $fs_medium_small;
      font-weight: $fw_normal;
      color: $c_white;
      margin-top: $spacing-xxxs;
      margin-bottom: $spacing-s;
    }

    &_pretext {
      font-size: $fs_medium_small;
      font-weight: $fw_normal;
      color: $c_white;
    }

    &_link {
      font-size: $fs_medium_small;
      font-weight: $fw_normal;
      color: $c_yellow;
      margin: 0;
    }

    &_text {
      font-size: $fs_medium_small;
      font-weight: $fw_normal;
      color: $c_cement-3;
      flex-basis: auto;
      justify-content: space-between;
      // max-width: 464px;
      > *:first-child {
        margin-top: 0;
      }
    }

    &_button_wrapper {
      background-color: $c_concrete-default;
      align-items: center;
      border: none;
      padding: $spacing-s $spacing-m;
      cursor: pointer;
    }

    &_button_text {
      font-size: $fs_base;
      font-weight: $fw_bold;
      color: $c_white;
      margin: 0;
    }

    &_button_icon {
      color: $c_yellow;
      height: 24px;
      width: 24px;
    }
  }
}
