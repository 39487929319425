.start-banner {
  &_preface {
    @extend .hero-description;
  }
}
.start {
  &_content {
    --layout-columns--min-width: 500px;
    --layout-columns--column-gap: #{$spacing-s * 2};
    --layout-columns--row-gap: #{$spacing-s};
    --list-box--max-height: 500px;

    width: 100%;
    margin: $spacing-l 0;
    display: flex;
    gap: $spacing-l;
    &_box {
      flex: 1;
    }

    &_title {
      margin-bottom: $fs_xsmall; //12px;
      line-height: 54px;
    }
    &_preface {
      font-size: $fs_medium; //21px;
      line-height: $fs_large; //30px;
      font-weight: 500;
      //   margin-bottom: $spacing-l;
    }
    &_subheader {
      font-size: $fs_medium_large; //24px;
      line-height: 36px;
      font-weight: 700;
      margin-bottom: 24px;
    }
  }

  button {
    margin: 10px;
  }
}
