.tools-menu-item {
  &:hover {
    color: $c_user_component_icon--hover;
    cursor: pointer;
  }
  label {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    &[data-active="true"] {
      color: $c_accent-highlight;
    }
  }
  &_button {
    display: flex;
    align-items: center;
    padding: 1rem 1rem 1rem 0.5rem;
    .icon-main {
      width: 24px;
      margin-right: 0.4em;
      svg {
        width: 100%;
        height: auto;
      }
    }
    .icon-chevron {
      width: 12px;
      transition: transform 0.2s ease;
      svg {
        width: 100%;
        height: auto;
      }
    }
    &[aria-expanded="true"] {
      .icon-chevron {
        transform: rotate(180deg) translate(0px, -3px);
      }
    }
  }

  &_menu {
    position: absolute;
    background-color: $c_white;
    box-shadow: 0px 10px 20px 0px #00000030;
    top: calc(100% + 22px);
    min-width: 120px;
    transform: translateX(-44px);
    z-index: 1;
    &::before {
      border: inset 22px;
      content: "";
      display: block;
      height: 0px;
      width: 0px;
      border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $c_white rgba(0, 0, 0, 0);
      border-bottom-style: solid;
      position: absolute;
      top: -42px;
      left: 40px;
      z-index: 89;
    }
  }
}
