.guide-viewer-requirement {
  background-color: $c_blue-2;
  margin-bottom: 1rem;
  border-left: 12px solid $c_not_started;

  &_holder {
    position: relative;
  }

  &_enabled {
    position: absolute;
    left: calc(100% + 1rem);
    top: 15px;
    cursor: pointer;

    input {
      display: none;
    }
  }

  &[data-status="done"] {
    border-left-color: $c_done;
  }
  &[data-status="reader"] {
    border-left-color: $c_in_progress;
  }
  &[data-status="disabled"] {
    border-left: 12px solid rgba($c_not_started, 0.3);

    .guide-viewer-requirement_tools,
    .guide-viewer-requirement_titlebar {
      opacity: 0.3;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: transparent;
      width: 100%;
      height: 100%;
      cursor: not-allowed;
    }
  }

  &_toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $c_blue-1;
    min-height: 42px;
  }
  &_responsible {
    font-size: $fs_xsmall;
    color: $c_cement-3;
  }
  &_title {
    font-size: 1.5em;
    font-weight: $fw_bold;
    line-height: 1.5em;
    padding: 0.33em 0.75em;
    margin: 0;
  }
  &_description {
    font-size: 1.31em;
    font-weight: $fw_medium_normal;
    line-height: 1.43em;
    padding: 1.14em;

    & > div > * {
      max-width: 33.62em;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      margin: 0;
      &:first-child {
        text-overflow: ellipsis;
      }
      &:not(:first-child) {
        display: none;
      }
    }

    &[data-is-open="true"] > div > * {
      display: inline-block;
      white-space: inherit;
      overflow: inherit;
      text-overflow: inherit;
      margin: 0 0 1em 0;
    }
  }
  &_responsible {
    margin-right: $spacing-xs;
    span {
      color: $c_white;
    }
  }
  &_tools {
    display: flex;
  }
  &_titlebar {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    background-color: $c_concrete-default;
    &_left {
      display: flex;
    }
  }

  &_content {
    background-color: $c_concrete-default;
    color: $c_cement-3;

    &_description {
      padding: $spacing-m 108px;
      font-weight: 500;
      font-size: $fs_medium;
      white-space: pre-line; // TODO: Remove when a text editor is implented for article ingress.
    }
    &_docs {
      padding: 0 108px $spacing-m 108px;
      font-size: $fs_small;
    }
    &_doc {
      @extend .link_to_document;
      margin-top: 0;
      line-height: $fs_small;
      display: block;
      color: $c_white;
      display: flex;
      align-items: center;
      margin-bottom: $spacing-xxxxs;
      cursor: pointer;
      .icon-download {
        width: 16px;
        color: $c_cement-default;
        margin-right: $spacing-xxxs;
        svg {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  &_button {
    max-height: 7.38em;

    @extend .button-guide-tool;

    svg {
      margin-bottom: 0;
    }

    &[data-icon="plus"] {
      color: $c_in_progress;
      padding: 2rem;
      justify-content: flex-start;
      background-color: transparent;
      border-color: transparent;
      svg {
        color: $c_in_progress;
      }
    }

    &[data-icon="done"] {
      width: 86px;
      min-height: 86px;
      padding-top: 2rem;
      justify-content: flex-start;
      .icon-tick {
        width: 24px;

        svg {
          width: 100%;
          height: auto;
        }
      }
      &[data-checked="true"] {
        background-color: $c_button-guide-tool_background;
        border-color: $c_button-guide-tool_background;
        .tick {
          color: $c_done;
        }
      }
    }
  }

  &_assign,
  &_comment {
    .icon-modal_button {
      flex-direction: row-reverse;
      padding: 1em;
      background-color: transparent;
      border-color: transparent;
      .icon {
        width: 16px;
        margin-left: 0.25rem;
      }
    }
  }
  &_assign {
    .icon {
      transform: translateY(1px);
    }
  }
  &_comment {
    .icon {
      transform: translateY(3px);
    }
  }
}
