@mixin table() {
  display: grid;
  background-color: $c_table_bg;
  padding: 16px 20px;
  border-bottom: 2px solid $c_table_border;
  font-size: 18px; //in design 21px
  line-height: 30px;
  &:hover {
    background-color: $c_table_bg--hover;
  }

  &.table-header {
    padding: 12px 20px 9px;
    background-color: $c_table_bg_header;
    font-size: 12px;
    line-height: 18px;
    div {
      cursor: pointer;
      &[aria-disabled="true"] {
        color: $c_table_text--disabled;
        cursor: default;
      }
    }
  }
}

@mixin setup-table($ns: "table") {
  .#{$ns} {
    @include table();
  }
}
@include setup-table();
