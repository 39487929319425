@font-face {
  font-family: "Circular";
  src: url("fonts/CircularXXSub-RegularSubset.woff2") format("woff2"),
    url("fonts/CircularXXSub-RegularSubset.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Circular";
  src: url("fonts/CircularXXSub-ItalicSubset.woff2") format("woff2"),
    url("fonts/CircularXXSub-ItalicSubset.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Circular";
  src: url("fonts/CircularXXSub-BookSubset.woff2") format("woff2"),
    url("fonts/CircularXXSub-BookSubset.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Circular";
  src: url("fonts/CircularXXSub-MediumSubset.woff2") format("woff2"),
    url("fonts/CircularXXSub-MediumSubset.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Circular";
  src: url("fonts/CircularXXSub-BoldSubset.woff2") format("woff2"),
    url("fonts/CircularXXSub-BoldSubset.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Circular";
  src: url("fonts/CircularXXSub-BlackSubset.woff2") format("woff2"),
    url("fonts/CircularXXSub-BlackSubset.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
