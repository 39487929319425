// @import "CreateGuide";
// @import "DeleteDocument";

.guide-viewer-section {
  &_blueprint {
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      min-height: inherit;
      left: 0;
      background-color: $c_yellow;
    }
    &_text {
      color: $c_text-inverted;
      font-size: $fs_medium;
      font-weight: $fw_bold;
      position: relative;
      text-align: center;
      width: 100%;
      &_back {
        color: $c_text-inverted;
        font-size: $fs_small;
        position: absolute;
        left: 0;
        top: 5px;
        display: flex;
        align-items: center;
        svg {
          margin-right: 0.5rem;
        }
      }
    }
  }
  &_header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &_export {
    max-width: $w_page-content-width;
    margin: 4rem auto;
  }
}
