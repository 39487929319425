.icon-modal {
  position: relative;

  &_button {
    @extend .button-guide-tool;
    height: 100%;

    .icon {
      width: 24px;

      svg {
        width: 100%;
        height: auto;
      }
    }
  }

  &.star > &_button {
    svg {
      stroke: none;
    }
  }

  &_modal {
    position: absolute;
    z-index: 10;
    background-color: $c_concrete-shade-1;

    min-width: 450px;
    right: 0;
    transform: translate(10px, 15px);

    &_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 66px;
      background-color: $c_concrete-shade-2;
    }

    &_title {
      flex: 1;
      padding-left: $spacing-m;
      padding-right: $spacing-m;
      margin-bottom: 0;
    }
    &_close {
      @extend .button;
      padding: 0;
      width: 66px;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &_button {
        width: 24px;
        transform: rotate(45deg) translateY(2px);

        svg {
          width: 100%;
          height: auto;
        }
      }
    }
    &_content {
      padding: $spacing-m;
      max-height: 400px;
      overflow-y: scroll;
    }
  }
}
