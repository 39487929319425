.company-list {
  &_menu {
    overflow-y: scroll;
    max-height: 80vh;
    width: auto;
    min-width: 230px;
    max-width: 300px;
  }

  &_item {
    width: 100%;
    &:not(:last-child) {
      border-bottom: 1px solid $c_concrete-shade-3;
    }
  }

  &_pretitle {
    text-transform: uppercase;
    font-size: 12px;
    padding: 5px 10px;
    display: block;
    color: $c_text;
    opacity: 0.5;
    border-bottom: 1px solid $c_menu_border;
  }
  &_linktext {
    margin-left: 15px;
    color: $c_grey-black;
    font-family: inherit;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: left;
    position: relative;
    .disabled & {
      color: $c_text--disabled;
    }
  }
  &_title {
    font-size: $fs_medium_small;
  }
  &_progress {
    font-size: $fs_xsmall;
    &[data-disabled="true"] {
      color: $c_cement-default;
    }
    &[data-type="italic"] {
      font-style: italic;
    }
  }

  &_link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $c_text;
    text-decoration: none;
    padding: 12px 12px 12px 0px;
    border-left: 6px solid rgba(0, 0, 0, 0);
    &.active {
      background-color: $c_grey-lighter;
      border-left: 6px solid $c_cyan-1;
      text-decoration: none;
    }
    &:hover {
      background-color: $c_grey-lighter;
      border-color: $c_menu_border--hover;
      text-decoration: none;
    }
  }

  &_no-result {
    display: block;
    padding: 12px;
  }
}
